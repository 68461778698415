import React, {Fragment, useState, useEffect, useCallback} from 'react';
import "./index.css";
import {TextToSpeech, Positions, Sizes} from "tts-react";
import SwitchTextSize from "react-font-size-resizer";

const Accesibility = ({children, className=''}) => {

    return (
        <Fragment>
            <div className={className}>
                <TextToSpeech
                    markTextAsSpoken
                    align="horizontal"
                    rate={0.8}
                    size={Sizes.SMALL}
                    position={Positions.LC}>
                    <div className={'accessibility-text'}>
                        <SwitchTextSize className={"accessibility-text-size"}/>
                    </div>
                    {children}
                </TextToSpeech>
            </div>
        </Fragment>
    );
}

export default Accesibility;
