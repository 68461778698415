import React, {Fragment, useState, useEffect, useCallback} from 'react';
import {Redirect} from "react-router-dom";
import Menu from "../menu";
import useCookie from "react-use-cookie";

const Header = ({subMenu, pageTitle, active, eventTime, setSelectedTab, hideMainMenu}) => {

    return (
        <Fragment>
            <Menu  active={active} subMenu={subMenu} pageTitle={pageTitle} eventTime={eventTime} setSelectedTab={setSelectedTab} hideMainMenu={hideMainMenu}/>
        </Fragment>
    );
}

export default Header;
