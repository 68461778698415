import React, { Fragment,useState,useEffect,useCallback } from 'react';

const Footer = (props) => {

  return (
      <Fragment>

          <footer className="footer-1 footer-wrap">
              <div className="footer-widgets section-bg pt-60 pb-60">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-6 col-xl-4 col-12">
                              <div className="single-footer-wid site-info-widget">
                                  <div className="wid-title">
                                      <h4>Get In Touch</h4>
                                  </div>
                                  <div className="contact-us">
                                      <div className="single-contact-info">
                                          <div className="icon">
                                              <i className="fal fa-phone"></i>
                                          </div>
                                          <div className="contact-info">
                                              <span>(+254) 0795 012 568</span>
                                          </div>
                                      </div>
                                      <div className="single-contact-info">
                                          <div className="icon">
                                              <i className="fal fa-envelope"></i>
                                          </div>
                                          <div className="contact-info">
                                              <span>info@ncikenya.go.ke</span>
                                              <span>support@ncikenya.go.ke</span>
                                          </div>
                                      </div>
                                      <div className="single-contact-info">
                                          <div className="icon">
                                              <i className="fal fa-map-marker-alt"></i>
                                          </div>
                                          <div className="contact-info">
                                              <span>6th Floor, Landmark Plaza, Off Argwings Kodhek Road, Upperhill<br/>Nairobi, KE.</span>
                                          </div>
                                      </div>
                                  </div>

                                  <div className="social-link">
                                      <a href="https://web.facebook.com/profile.php?id=61550108184629"><i className="fab fa-facebook-f"></i></a>
                                      <a href="https://twitter.com/ncikenya"><i className="fab fa-twitter"></i></a>
                                      {/*<a href="#"><i className="fab fa-instagram"></i></a>*/}
                                      <a href="https://www.youtube.com/@nationalcancerinstituteofk8933"><i className="fab fa-youtube"></i></a>
                                  </div>
                              </div>
                          </div>

                          <div className="col-md-6 col-xl-4 offset-xl-1 col-12">
                              <div className="single-footer-wid">
                                  <div className="wid-title">
                                      <h4>About Us</h4>
                                  </div>
                                  <div className="special-menu">
                                      <ul>
                                          <li><a href="/about">About</a></li>
                                          <li><a href="/about#mandate">Mandate</a></li>
                                          <li><a href="/about#mission">Mission, Vision & Values</a></li>
                                          <li><a href="/about#charter">Service charter</a></li>
                                          <li><a href="/about#governance">Governance structure</a></li>
                                          <li><a href="/about#achievements">Achievements</a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>

                          <div className="col-md-6 col-xl-3 col-12">
                              <div className="single-footer-wid">
                                  <div className="wid-title">
                                      <h4>Related Organizations</h4>
                                  </div>
                                  <ul>
                                      <li><a href="https://kenconetwork.org">KENCO</a></li>
                                      <li><a href="https://www.nhif.or.ke/">NHIF</a></li>
                                      <li><a href="https://www.kemsa.co.ke/">KEMSA</a></li>
                                  </ul>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
              <div className="footer-bottom">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-12 col-12 text-center">
                              <div className="copyright-info">
                                  <p>&copy; <a href="/">NCI Kenya</a> .All rights reserved.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
    </Fragment>
  );
}

export default Footer;
