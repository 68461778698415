import React, {Fragment, useState, useEffect, useCallback} from 'react';
import "./index.css";
import {Nav} from "react-bootstrap";
import backgroundPattern from "../../../assets/img/background-repeat.jpg";
import Countdown from "react-countdown-simple";
import {DateTime} from "luxon";

const ContentMenu = ({menu, pageTitle, eventTime, setSelectedTab, hideMainMenu}) => {

    return (
        <Fragment>
            {!hideMainMenu && <div className={"p-3 event-menu sub-menu-img d-flex justify-content-center align-items-center" + (eventTime ? " flex-column" : "")}
                  style={{backgroundSize: menu[0]?.backgroundRepeating ? 'repeat !important' : 'cover', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${menu[0]?.backgroundRepeating ?? backgroundPattern})`}}>
                <h1 className={"text-white text-center"}>{pageTitle}</h1>
                {eventTime ? <>
                    <h2 className={"text-white"}>{DateTime.fromJSDate(eventTime).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}</h2><Countdown
                    targetDate={eventTime}
                    renderer={({days, hours, minutes, seconds}) => (
                        <div>
                            {days} Days - {hours} Hrs - {minutes} Min - {seconds} Sec
                        </div>
                    )}
                />
                </> : null}
            </div>}
            <div className="header-wrap header-box-style header-1">
                <div className="col-12 pr-0 pl-0">
                    <div className="box-wrap top-box-wrap">
                        <div className="menu-wrap d-flex align-items-center justify-content-around">
                            <div className="top-menu">
                                <Nav variant="pills" className="flex-row">
                                    {menu.map((menuItem) => <Nav.Item  key={menuItem.key}>
                                        <Nav.Link eventKey={menuItem.key} onClick={()=>setSelectedTab(menuItem.key)}>{menuItem.title}</Nav.Link>
                                    </Nav.Item>)}
                                </Nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ContentMenu;
