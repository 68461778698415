import React, {Fragment, useState, useEffect, Suspense} from 'react'
// @ts-ignore
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
// @ts-ignore
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import ReactDOM from 'react-dom/client';
import './index.css';
import {routes} from './routes';
import reportWebVitals from './reportWebVitals';
import {UserRoles} from "./config/utils";
import {PrivateRoute} from "./routes/PrivateRoute";
import App from "./App";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit";


const Root = () => {
    const { speak } = useSpeechSynthesis();
    const [anim, setAnim] = useState("");
    const abortController = new AbortController();
    const [currentUser, setCurrentUser] = useState(false);
    const [authenticated, setAuthenticated] = useState(false)
    const jwt_token = localStorage.getItem('token') ?? {};

    useEffect(() => {

        return function cleanup() {
            abortController.abort();
        }
        // eslint-disable-next-line
    }, []);



    return (
        <BrowserRouter basename={`/`}>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    {/*<Route exact path={`${process.env.PUBLIC_URL}/`} component={App}></Route>*/}


                    <App>
                        {/*<Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {*/}
                        {/*    return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)*/}
                        {/*}} /> */}
                        <TransitionGroup>
                            {routes.map(({path, Component, allowedRoles}) => {
                                    allowedRoles = [UserRoles.ADMIN, UserRoles.SUPER].concat(allowedRoles ?? [])
                                    return <PrivateRoute key={path} exact path={`${process.env.PUBLIC_URL}${path}`} component={Component} allowedRoles={allowedRoles}>
                                    </PrivateRoute>
                                }
                            )}
                        </TransitionGroup>

                    </App>


                    {/*{routes.map(({path, Component, allowedRoles}) => {*/}
                    {/*        return <Route key={path} exact allowedRoles={allowedRoles} component={Component}path={`${process.env.PUBLIC_URL}${path}`}></Route>*/}
                    {/*    }*/}
                    {/*)}*/}

                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<Root/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
