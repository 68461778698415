import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {UserRoles} from "../config/utils";


export const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => (
    <Route {...rest} render={props => {

        if(!allowedRoles || !allowedRoles?.includes(UserRoles.PUBLIC)) {
            const currentUser = JSON.parse(localStorage.getItem("token"))?.user;
            if (!currentUser) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{pathname: '/login'}}/>
            }

            // check if route is restricted by role
            if (!allowedRoles || (allowedRoles?.filter(allowedRole => currentUser.roles.includes(allowedRole))?.length ?? 0) <= 0) {
                // role not authorised so redirect to home page

                return <Redirect to={{pathname: currentUser.roles.includes(UserRoles.USER) ? '' : '/login'}}/>
            }
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)
