import React, { Fragment } from 'react';
import {withRouter} from "react-router-dom";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import {useApolloClient} from "./apollo/client";
import {ApolloProvider} from "@apollo/client";
import {ReCaptchaProvider} from "react-recaptcha-x";
import { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.min.css';
import {ToastContainer} from "react-toastify";

const App = ({children}) =>  {
    const apolloClient = useApolloClient();

    return (
        <ReCaptchaProvider
            siteKeyV2={'6LdLK1cmAAAAADWrnHR6OLTci_ZiusSj7A2UtkE2'}
            langCode="en"
            hideV3Badge={false}
        >
        <ApolloProvider client={apolloClient}>
        <Fragment>
            {children}
            <Footer/>
            <Toaster />
            <ToastContainer/>
        </Fragment>
        </ApolloProvider>
        </ReCaptchaProvider>
    );
}

export default withRouter(App);
