// dashbaord
import React from "react";
import {UserRoles} from "../config/utils";
import WorkWithUs from "../components/procurement";
const Mission = React.lazy(() => import("../components/about/mission"));
const CancerCenters = React.lazy(() => import("../components/cancercenters"));
const Home = React.lazy(() => import("../components/homepage"));
const AboutCancer = React.lazy(() => import("../components/about-cancer"));
const Media = React.lazy(() => import("../components/media"));
const WhatWeDo = React.lazy(() => import("../components/what-we-do"));
const CancerAwareness = React.lazy(() => import("../components/cancer-awareness"));
const NewsAndEvents = React.lazy(() => import("../components/newsevents"));
const EventDetails = React.lazy(() => import("../components/event-details"));
const BlogDetails = React.lazy(() => import("../components/blog-details"));
const CustomEventDetails = React.lazy(() => import("../components/event-details/custom-event"));
const ResearchAndStatistics = React.lazy(() => import("../components/research"));
const Resources = React.lazy(() => import("../components/resources"));
const AboutUs = React.lazy(() => import("../components/about"));
const Contacts = React.lazy(() => import("../components/contacts"));
const Procurement = React.lazy(() => import("../components/procurement"));
const FAQ = React.lazy(() => import("../components/faq"));
const Career = React.lazy(() => import("../components/career"));



export const routes = [

        { path:"/admin", Component:Mission, allowedRoles:[UserRoles.ADMIN, UserRoles.SUPER]},
        // { path:"/", Component:CustomEventDetails, allowedRoles:[UserRoles.PUBLIC]},
        { path:"/", Component:Home, allowedRoles:[UserRoles.PUBLIC]},
        { path:"/cancer-summit", Component:CustomEventDetails, allowedRoles:[UserRoles.PUBLIC]},
        { path:"/cancer", Component:AboutCancer, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/what-we-do", Component:WhatWeDo, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/cancer-awareness", Component:CancerAwareness, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/centers", Component:CancerCenters, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/news-and-events", Component:NewsAndEvents, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/research", Component:ResearchAndStatistics, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/resources", Component:Resources, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/about", Component:AboutUs, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/contacts", Component:Contacts, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/event/:id", Component:EventDetails, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/blog/:id", Component:BlogDetails, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/cancer-summit", Component:CustomEventDetails, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/mission", Component:Mission, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/media", Component:Media, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/procurement", Component:Procurement, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/faq", Component:FAQ, allowedRoles:[ UserRoles.PUBLIC]},
        { path:"/careers", Component:Career, allowedRoles:[ UserRoles.PUBLIC]},

]
