import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ConnectionCursor: any;
  DateTime: any;
  Timestamp: any;
  Upload: any;
};

export type AddDocumentsToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMediasToBlogInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMediasToEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMediasToJobPostInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMediasToRegulationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMediasToTenderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type Blog = {
  __typename?: 'Blog';
  content: Scalars['String'];
  coverImage: Media;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdBy: PublicUser;
  createdById?: Maybe<Scalars['Float']>;
  enabled: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  medias?: Maybe<Array<Media>>;
  mediasAggregate: Array<BlogMediasAggregateResponse>;
  title: Scalars['String'];
};


export type BlogMediasArgs = {
  filter?: InputMaybe<MediaFilter>;
  sorting?: InputMaybe<Array<MediaSort>>;
};


export type BlogMediasAggregateArgs = {
  filter?: InputMaybe<MediaAggregateFilter>;
};

export type BlogAggregateFilter = {
  and?: InputMaybe<Array<BlogAggregateFilter>>;
  content?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdById?: InputMaybe<NumberFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BlogAggregateFilter>>;
  title?: InputMaybe<StringFieldComparison>;
};

export type BlogAggregateGroupBy = {
  __typename?: 'BlogAggregateGroupBy';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type BlogAggregateResponse = {
  __typename?: 'BlogAggregateResponse';
  avg?: Maybe<BlogAvgAggregate>;
  count?: Maybe<BlogCountAggregate>;
  groupBy?: Maybe<BlogAggregateGroupBy>;
  max?: Maybe<BlogMaxAggregate>;
  min?: Maybe<BlogMinAggregate>;
  sum?: Maybe<BlogSumAggregate>;
};

export type BlogAvgAggregate = {
  __typename?: 'BlogAvgAggregate';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type BlogConnection = {
  __typename?: 'BlogConnection';
  /** Array of nodes. */
  nodes: Array<Blog>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type BlogCountAggregate = {
  __typename?: 'BlogCountAggregate';
  content?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
};

export type BlogFilter = {
  and?: InputMaybe<Array<BlogFilter>>;
  content?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdById?: InputMaybe<NumberFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BlogFilter>>;
  title?: InputMaybe<StringFieldComparison>;
};

export type BlogInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  createdById?: InputMaybe<Scalars['Float']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type BlogMaxAggregate = {
  __typename?: 'BlogMaxAggregate';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type BlogMediasAggregateGroupBy = {
  __typename?: 'BlogMediasAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type BlogMediasAggregateResponse = {
  __typename?: 'BlogMediasAggregateResponse';
  avg?: Maybe<BlogMediasAvgAggregate>;
  count?: Maybe<BlogMediasCountAggregate>;
  groupBy?: Maybe<BlogMediasAggregateGroupBy>;
  max?: Maybe<BlogMediasMaxAggregate>;
  min?: Maybe<BlogMediasMinAggregate>;
  sum?: Maybe<BlogMediasSumAggregate>;
};

export type BlogMediasAvgAggregate = {
  __typename?: 'BlogMediasAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BlogMediasCountAggregate = {
  __typename?: 'BlogMediasCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type BlogMediasMaxAggregate = {
  __typename?: 'BlogMediasMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type BlogMediasMinAggregate = {
  __typename?: 'BlogMediasMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type BlogMediasSumAggregate = {
  __typename?: 'BlogMediasSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BlogMinAggregate = {
  __typename?: 'BlogMinAggregate';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type BlogSort = {
  direction: SortDirection;
  field: BlogSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BlogSortFields {
  Content = 'content',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Enabled = 'enabled',
  Id = 'id',
  Title = 'title'
}

export type BlogSumAggregate = {
  __typename?: 'BlogSumAggregate';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type BlogUpdateInput = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  createdById?: InputMaybe<Scalars['Float']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
};

export type CancerCenter = {
  __typename?: 'CancerCenter';
  address: Scalars['String'];
  county: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdBy: PublicUser;
  createdById?: Maybe<Scalars['Float']>;
  designation: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  modalities: Scalars['String'];
  name: Scalars['String'];
  ownership: CenterOwnershipType;
};

export type CancerCenterAggregateFilter = {
  address?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<CancerCenterAggregateFilter>>;
  county?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdById?: InputMaybe<NumberFieldComparison>;
  designation?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  modalities?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CancerCenterAggregateFilter>>;
  ownership?: InputMaybe<CenterOwnershipTypeFilterComparison>;
};

export type CancerCenterAggregateGroupBy = {
  __typename?: 'CancerCenterAggregateGroupBy';
  address?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  designation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  modalities?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownership?: Maybe<CenterOwnershipType>;
};

export type CancerCenterAggregateResponse = {
  __typename?: 'CancerCenterAggregateResponse';
  avg?: Maybe<CancerCenterAvgAggregate>;
  count?: Maybe<CancerCenterCountAggregate>;
  groupBy?: Maybe<CancerCenterAggregateGroupBy>;
  max?: Maybe<CancerCenterMaxAggregate>;
  min?: Maybe<CancerCenterMinAggregate>;
  sum?: Maybe<CancerCenterSumAggregate>;
};

export type CancerCenterAvgAggregate = {
  __typename?: 'CancerCenterAvgAggregate';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type CancerCenterConnection = {
  __typename?: 'CancerCenterConnection';
  /** Array of nodes. */
  nodes: Array<CancerCenter>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type CancerCenterCountAggregate = {
  __typename?: 'CancerCenterCountAggregate';
  address?: Maybe<Scalars['Int']>;
  county?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  designation?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  modalities?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  ownership?: Maybe<Scalars['Int']>;
};

export type CancerCenterFilter = {
  address?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<CancerCenterFilter>>;
  county?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdById?: InputMaybe<NumberFieldComparison>;
  designation?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  modalities?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CancerCenterFilter>>;
  ownership?: InputMaybe<CenterOwnershipTypeFilterComparison>;
};

export type CancerCenterInput = {
  address: Scalars['String'];
  county: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  createdById?: InputMaybe<Scalars['Float']>;
  designation: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  modalities: Scalars['String'];
  name: Scalars['String'];
  ownership: CenterOwnershipType;
};

export type CancerCenterMaxAggregate = {
  __typename?: 'CancerCenterMaxAggregate';
  address?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  designation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  modalities?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownership?: Maybe<CenterOwnershipType>;
};

export type CancerCenterMinAggregate = {
  __typename?: 'CancerCenterMinAggregate';
  address?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  designation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  modalities?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownership?: Maybe<CenterOwnershipType>;
};

export type CancerCenterSort = {
  direction: SortDirection;
  field: CancerCenterSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CancerCenterSortFields {
  Address = 'address',
  County = 'county',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Designation = 'designation',
  Id = 'id',
  Modalities = 'modalities',
  Name = 'name',
  Ownership = 'ownership'
}

export type CancerCenterSumAggregate = {
  __typename?: 'CancerCenterSumAggregate';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type CancerCenterUpdateInput = {
  address: Scalars['String'];
  county: Scalars['String'];
  designation: Scalars['String'];
  modalities: Scalars['String'];
  name: Scalars['String'];
  ownership: CenterOwnershipType;
};

export type CarouselConnection = {
  __typename?: 'CarouselConnection';
  /** Array of nodes. */
  nodes: Array<Carousel>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export enum CenterOwnershipType {
  FaithBased = 'FaithBased',
  Private = 'Private',
  Public = 'Public'
}

export type CenterOwnershipTypeFilterComparison = {
  eq?: InputMaybe<CenterOwnershipType>;
  gt?: InputMaybe<CenterOwnershipType>;
  gte?: InputMaybe<CenterOwnershipType>;
  iLike?: InputMaybe<CenterOwnershipType>;
  in?: InputMaybe<Array<CenterOwnershipType>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<CenterOwnershipType>;
  lt?: InputMaybe<CenterOwnershipType>;
  lte?: InputMaybe<CenterOwnershipType>;
  neq?: InputMaybe<CenterOwnershipType>;
  notILike?: InputMaybe<CenterOwnershipType>;
  notIn?: InputMaybe<Array<CenterOwnershipType>>;
  notLike?: InputMaybe<CenterOwnershipType>;
};

export type Complaint = {
  __typename?: 'Complaint';
  id: Scalars['ID'];
};

export type ComplaintAggregateGroupBy = {
  __typename?: 'ComplaintAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type ComplaintAvgAggregate = {
  __typename?: 'ComplaintAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ComplaintCountAggregate = {
  __typename?: 'ComplaintCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type ComplaintEdge = {
  __typename?: 'ComplaintEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Complaint */
  node: Complaint;
};

export type ComplaintInput = {
  requestId: Scalars['ID'];
};

export type ComplaintMaxAggregate = {
  __typename?: 'ComplaintMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ComplaintMinAggregate = {
  __typename?: 'ComplaintMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ComplaintSumAggregate = {
  __typename?: 'ComplaintSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type CreateOneComplaintInput = {
  /** The record to create */
  complaint: ComplaintInput;
};

export type CurrentConfiguration = {
  __typename?: 'CurrentConfiguration';
  adminPanelAPIKey: Scalars['String'];
  backendMapsAPIKey: Scalars['String'];
  categories: Scalars['String'];
  firebaseProjectPrivateKey: Scalars['String'];
  purchaseCode: Scalars['String'];
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export type Event = {
  __typename?: 'Event';
  canRegister: Scalars['Boolean'];
  category: Scalars['String'];
  content: Scalars['String'];
  coverImage: Media;
  createdBy: PublicUser;
  enabled: Scalars['Boolean'];
  from: Scalars['Timestamp'];
  id?: Maybe<Scalars['ID']>;
  isHtml: Scalars['Boolean'];
  medias: Array<Media>;
  mediasAggregate: Array<EventMediasAggregateResponse>;
  program: Array<EventProgramDto>;
  speakers: Array<PublicSpeakerDto>;
  title: Scalars['String'];
  to: Scalars['Timestamp'];
  venue: Scalars['String'];
  venueLink?: Maybe<Scalars['String']>;
};


export type EventMediasArgs = {
  filter?: InputMaybe<MediaFilter>;
  sorting?: InputMaybe<Array<MediaSort>>;
};


export type EventMediasAggregateArgs = {
  filter?: InputMaybe<MediaAggregateFilter>;
};

export type EventAggregateFilter = {
  and?: InputMaybe<Array<EventAggregateFilter>>;
  canRegister?: InputMaybe<BooleanFieldComparison>;
  category?: InputMaybe<StringFieldComparison>;
  content?: InputMaybe<StringFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  from?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isHtml?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<EventAggregateFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  to?: InputMaybe<DateFieldComparison>;
  venue?: InputMaybe<StringFieldComparison>;
  venueLink?: InputMaybe<StringFieldComparison>;
};

export type EventAggregateGroupBy = {
  __typename?: 'EventAggregateGroupBy';
  canRegister?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  from?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['ID']>;
  isHtml?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['Timestamp']>;
  venue?: Maybe<Scalars['String']>;
  venueLink?: Maybe<Scalars['String']>;
};

export type EventAggregateResponse = {
  __typename?: 'EventAggregateResponse';
  avg?: Maybe<EventAvgAggregate>;
  count?: Maybe<EventCountAggregate>;
  groupBy?: Maybe<EventAggregateGroupBy>;
  max?: Maybe<EventMaxAggregate>;
  min?: Maybe<EventMinAggregate>;
  sum?: Maybe<EventSumAggregate>;
};

export type EventAvgAggregate = {
  __typename?: 'EventAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type EventConnection = {
  __typename?: 'EventConnection';
  /** Array of nodes. */
  nodes: Array<Event>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type EventCountAggregate = {
  __typename?: 'EventCountAggregate';
  canRegister?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isHtml?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  venue?: Maybe<Scalars['Int']>;
  venueLink?: Maybe<Scalars['Int']>;
};

export type EventFilter = {
  and?: InputMaybe<Array<EventFilter>>;
  canRegister?: InputMaybe<BooleanFieldComparison>;
  category?: InputMaybe<StringFieldComparison>;
  content?: InputMaybe<StringFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  from?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isHtml?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<EventFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  to?: InputMaybe<DateFieldComparison>;
  venue?: InputMaybe<StringFieldComparison>;
  venueLink?: InputMaybe<StringFieldComparison>;
};

export type EventInput = {
  canRegister?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  from: Scalars['Timestamp'];
  id?: InputMaybe<Scalars['ID']>;
  isHtml?: InputMaybe<Scalars['Boolean']>;
  program?: InputMaybe<Array<EventProgramInput>>;
  speakers?: InputMaybe<Array<PublicSpeakerInput>>;
  title: Scalars['String'];
  to: Scalars['Timestamp'];
  venue: Scalars['String'];
  venueLink?: InputMaybe<Scalars['String']>;
};

export type EventMaxAggregate = {
  __typename?: 'EventMaxAggregate';
  category?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['Timestamp']>;
  venue?: Maybe<Scalars['String']>;
  venueLink?: Maybe<Scalars['String']>;
};

export type EventMediasAggregateGroupBy = {
  __typename?: 'EventMediasAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type EventMediasAggregateResponse = {
  __typename?: 'EventMediasAggregateResponse';
  avg?: Maybe<EventMediasAvgAggregate>;
  count?: Maybe<EventMediasCountAggregate>;
  groupBy?: Maybe<EventMediasAggregateGroupBy>;
  max?: Maybe<EventMediasMaxAggregate>;
  min?: Maybe<EventMediasMinAggregate>;
  sum?: Maybe<EventMediasSumAggregate>;
};

export type EventMediasAvgAggregate = {
  __typename?: 'EventMediasAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type EventMediasCountAggregate = {
  __typename?: 'EventMediasCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type EventMediasMaxAggregate = {
  __typename?: 'EventMediasMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type EventMediasMinAggregate = {
  __typename?: 'EventMediasMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type EventMediasSumAggregate = {
  __typename?: 'EventMediasSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type EventMinAggregate = {
  __typename?: 'EventMinAggregate';
  category?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['Timestamp']>;
  venue?: Maybe<Scalars['String']>;
  venueLink?: Maybe<Scalars['String']>;
};

export type EventProgramDto = {
  __typename?: 'EventProgramDTO';
  from: Scalars['Timestamp'];
  speakers: Array<PublicSpeakerDto>;
  title: Scalars['String'];
  to: Scalars['Timestamp'];
};

export type EventProgramInput = {
  from: Scalars['Timestamp'];
  speakers: Array<PublicSpeakerInput>;
  title: Scalars['String'];
  to: Scalars['Timestamp'];
};

export type EventSort = {
  direction: SortDirection;
  field: EventSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EventSortFields {
  CanRegister = 'canRegister',
  Category = 'category',
  Content = 'content',
  Enabled = 'enabled',
  From = 'from',
  Id = 'id',
  IsHtml = 'isHtml',
  Title = 'title',
  To = 'to',
  Venue = 'venue',
  VenueLink = 'venueLink'
}

export type EventSumAggregate = {
  __typename?: 'EventSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type EventUpdateInput = {
  canRegister?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  from?: InputMaybe<Scalars['Timestamp']>;
  isHtml?: InputMaybe<Scalars['Boolean']>;
  program?: InputMaybe<Array<EventProgramInput>>;
  speakers?: InputMaybe<Array<PublicSpeakerInput>>;
  title?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['Timestamp']>;
  venue?: InputMaybe<Scalars['String']>;
  venueLink?: InputMaybe<Scalars['String']>;
};

export type FeedbackInput = {
  content: Scalars['String'];
  email: Scalars['String'];
  fullNames: Scalars['String'];
  token: Scalars['String'];
};

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  iLike?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  neq?: InputMaybe<Scalars['ID']>;
  notILike?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
  notLike?: InputMaybe<Scalars['ID']>;
};

export type JobPost = {
  __typename?: 'JobPost';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdBy: PublicUser;
  createdById?: Maybe<Scalars['Float']>;
  enabled: Scalars['Boolean'];
  file: Media;
  id?: Maybe<Scalars['ID']>;
  location: Scalars['String'];
  medias?: Maybe<Array<Media>>;
  mediasAggregate: Array<JobPostMediasAggregateResponse>;
  position: Scalars['String'];
  title: Scalars['String'];
};


export type JobPostMediasArgs = {
  filter?: InputMaybe<MediaFilter>;
  sorting?: InputMaybe<Array<MediaSort>>;
};


export type JobPostMediasAggregateArgs = {
  filter?: InputMaybe<MediaAggregateFilter>;
};

export type JobPostAggregateFilter = {
  and?: InputMaybe<Array<JobPostAggregateFilter>>;
  applicationDeadline?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdById?: InputMaybe<NumberFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  location?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<JobPostAggregateFilter>>;
  position?: InputMaybe<StringFieldComparison>;
  title?: InputMaybe<StringFieldComparison>;
};

export type JobPostAggregateGroupBy = {
  __typename?: 'JobPostAggregateGroupBy';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type JobPostAggregateResponse = {
  __typename?: 'JobPostAggregateResponse';
  avg?: Maybe<JobPostAvgAggregate>;
  count?: Maybe<JobPostCountAggregate>;
  groupBy?: Maybe<JobPostAggregateGroupBy>;
  max?: Maybe<JobPostMaxAggregate>;
  min?: Maybe<JobPostMinAggregate>;
  sum?: Maybe<JobPostSumAggregate>;
};

export type JobPostAvgAggregate = {
  __typename?: 'JobPostAvgAggregate';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type JobPostConnection = {
  __typename?: 'JobPostConnection';
  /** Array of nodes. */
  nodes: Array<JobPost>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type JobPostCountAggregate = {
  __typename?: 'JobPostCountAggregate';
  applicationDeadline?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
};

export type JobPostFilter = {
  and?: InputMaybe<Array<JobPostFilter>>;
  applicationDeadline?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdById?: InputMaybe<NumberFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  location?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<JobPostFilter>>;
  position?: InputMaybe<StringFieldComparison>;
  title?: InputMaybe<StringFieldComparison>;
};

export type JobPostInput = {
  applicationDeadline?: InputMaybe<Scalars['Timestamp']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  createdById?: InputMaybe<Scalars['Float']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  location: Scalars['String'];
  position: Scalars['String'];
  title: Scalars['String'];
};

export type JobPostMaxAggregate = {
  __typename?: 'JobPostMaxAggregate';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type JobPostMediasAggregateGroupBy = {
  __typename?: 'JobPostMediasAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type JobPostMediasAggregateResponse = {
  __typename?: 'JobPostMediasAggregateResponse';
  avg?: Maybe<JobPostMediasAvgAggregate>;
  count?: Maybe<JobPostMediasCountAggregate>;
  groupBy?: Maybe<JobPostMediasAggregateGroupBy>;
  max?: Maybe<JobPostMediasMaxAggregate>;
  min?: Maybe<JobPostMediasMinAggregate>;
  sum?: Maybe<JobPostMediasSumAggregate>;
};

export type JobPostMediasAvgAggregate = {
  __typename?: 'JobPostMediasAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type JobPostMediasCountAggregate = {
  __typename?: 'JobPostMediasCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type JobPostMediasMaxAggregate = {
  __typename?: 'JobPostMediasMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type JobPostMediasMinAggregate = {
  __typename?: 'JobPostMediasMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type JobPostMediasSumAggregate = {
  __typename?: 'JobPostMediasSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type JobPostMinAggregate = {
  __typename?: 'JobPostMinAggregate';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type JobPostSort = {
  direction: SortDirection;
  field: JobPostSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum JobPostSortFields {
  ApplicationDeadline = 'applicationDeadline',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Enabled = 'enabled',
  Id = 'id',
  Location = 'location',
  Position = 'position',
  Title = 'title'
}

export type JobPostSumAggregate = {
  __typename?: 'JobPostSumAggregate';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type JobPostUpdateInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Login = {
  __typename?: 'Login';
  activated: Scalars['Boolean'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  jwtToken: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  media: Media;
  mobileNumber?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  addresses: Array<Scalars['String']>;
  id: Scalars['ID'];
  thumbnails: Array<Scalars['String']>;
};

export type MediaAggregateFilter = {
  and?: InputMaybe<Array<MediaAggregateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<MediaAggregateFilter>>;
};

export type MediaAggregateGroupBy = {
  __typename?: 'MediaAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type MediaAvgAggregate = {
  __typename?: 'MediaAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MediaCountAggregate = {
  __typename?: 'MediaCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type MediaEdge = {
  __typename?: 'MediaEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Media */
  node: Media;
};

export type MediaFilter = {
  and?: InputMaybe<Array<MediaFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<MediaFilter>>;
};

export type MediaMaxAggregate = {
  __typename?: 'MediaMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type MediaMinAggregate = {
  __typename?: 'MediaMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type MediaSort = {
  direction: SortDirection;
  field: MediaSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MediaSortFields {
  Id = 'id'
}

export type MediaSumAggregate = {
  __typename?: 'MediaSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addDocumentsToUser: User;
  addMediasToBlog: Blog;
  addMediasToEvent: Event;
  addMediasToJobPost: JobPost;
  addMediasToRegulation: Regulation;
  addMediasToTender: Tender;
  createAccount: User;
  createBlog: Blog;
  createCancerCenter: CancerCenter;
  createCarousel: Carousel;
  createEvent: Event;
  createJobPost: JobPost;
  createOneComplaint: Complaint;
  createRegulation: Regulation;
  createTender: Tender;
  deleteBlog: Scalars['Boolean'];
  deleteCancerCenter: Scalars['Boolean'];
  deleteCarousel: Scalars['Boolean'];
  deleteEvent: Scalars['Boolean'];
  deleteJobPost: Scalars['Int'];
  deleteRegulation: Scalars['Int'];
  deleteTender: Scalars['Int'];
  login: Login;
  logout: Scalars['Boolean'];
  newFeedback: Scalars['Boolean'];
  removeCoverImageFromBlog: Blog;
  removeCoverImageFromEvent: Event;
  removeCreatedByFromBlog: Blog;
  removeCreatedByFromCancerCenter: CancerCenter;
  removeCreatedByFromCarousel: Carousel;
  removeCreatedByFromEvent: Event;
  removeCreatedByFromJobPost: JobPost;
  removeCreatedByFromRegulation: Regulation;
  removeCreatedByFromTender: Tender;
  removeDocumentsFromUser: User;
  removeFileFromJobPost: JobPost;
  removeFileFromRegulation: Regulation;
  removeFileFromTender: Tender;
  removeMediaFromCarousel: Carousel;
  removeMediaFromPublicUser: PublicUser;
  removeMediaFromUser: User;
  removeMediasFromBlog: Blog;
  removeMediasFromEvent: Event;
  removeMediasFromJobPost: JobPost;
  removeMediasFromRegulation: Regulation;
  removeMediasFromTender: Tender;
  removeRoleFromUser: User;
  setCoverImageOnBlog: Blog;
  setCoverImageOnEvent: Event;
  setCreatedByOnBlog: Blog;
  setCreatedByOnCancerCenter: CancerCenter;
  setCreatedByOnCarousel: Carousel;
  setCreatedByOnEvent: Event;
  setCreatedByOnJobPost: JobPost;
  setCreatedByOnRegulation: Regulation;
  setCreatedByOnTender: Tender;
  setDocumentsOnUser: User;
  setFileOnJobPost: JobPost;
  setFileOnRegulation: Regulation;
  setFileOnTender: Tender;
  setMediaOnCarousel: Carousel;
  setMediaOnPublicUser: PublicUser;
  setMediaOnUser: User;
  setMediasOnBlog: Blog;
  setMediasOnEvent: Event;
  setMediasOnJobPost: JobPost;
  setMediasOnRegulation: Regulation;
  setMediasOnTender: Tender;
  setRoleOnUser: User;
  updateBlog: Blog;
  updateCancerCenter: CancerCenter;
  updateCarousel: Carousel;
  updateEvent: Event;
  updateJobPost: JobPost;
  updateRegulation: Regulation;
  updateSelf: User;
  updateTender: Tender;
};


export type MutationAddDocumentsToUserArgs = {
  input: AddDocumentsToUserInput;
};


export type MutationAddMediasToBlogArgs = {
  input: AddMediasToBlogInput;
};


export type MutationAddMediasToEventArgs = {
  input: AddMediasToEventInput;
};


export type MutationAddMediasToJobPostArgs = {
  input: AddMediasToJobPostInput;
};


export type MutationAddMediasToRegulationArgs = {
  input: AddMediasToRegulationInput;
};


export type MutationAddMediasToTenderArgs = {
  input: AddMediasToTenderInput;
};


export type MutationCreateAccountArgs = {
  input: LoginInput;
};


export type MutationCreateBlogArgs = {
  blogInput: BlogInput;
  coverImage: Scalars['Upload'];
  otherMedias?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationCreateCancerCenterArgs = {
  cancerCenterInput: CancerCenterInput;
};


export type MutationCreateCarouselArgs = {
  carouselImage: Scalars['Upload'];
  createCarouselInput: CarouselInput;
};


export type MutationCreateEventArgs = {
  coverImage: Scalars['Upload'];
  createEventInput: EventInput;
  otherMedias?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationCreateJobPostArgs = {
  files: Array<Scalars['Upload']>;
  jobPostInput: JobPostInput;
};


export type MutationCreateOneComplaintArgs = {
  input: CreateOneComplaintInput;
};


export type MutationCreateRegulationArgs = {
  files: Array<Scalars['Upload']>;
  otherMedias?: InputMaybe<Array<Scalars['Upload']>>;
  regulationInput: RegulationInput;
};


export type MutationCreateTenderArgs = {
  files: Array<Scalars['Upload']>;
  otherMedias?: InputMaybe<Array<Scalars['Upload']>>;
  tenderInput: TenderInput;
};


export type MutationDeleteBlogArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCancerCenterArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCarouselArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteJobPostArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRegulationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTenderArgs = {
  id: Scalars['Int'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationNewFeedbackArgs = {
  feedbackInput: FeedbackInput;
};


export type MutationRemoveCoverImageFromBlogArgs = {
  input: RemoveCoverImageFromBlogInput;
};


export type MutationRemoveCoverImageFromEventArgs = {
  input: RemoveCoverImageFromEventInput;
};


export type MutationRemoveCreatedByFromBlogArgs = {
  input: RemoveCreatedByFromBlogInput;
};


export type MutationRemoveCreatedByFromCancerCenterArgs = {
  input: RemoveCreatedByFromCancerCenterInput;
};


export type MutationRemoveCreatedByFromCarouselArgs = {
  input: RemoveCreatedByFromCarouselInput;
};


export type MutationRemoveCreatedByFromEventArgs = {
  input: RemoveCreatedByFromEventInput;
};


export type MutationRemoveCreatedByFromJobPostArgs = {
  input: RemoveCreatedByFromJobPostInput;
};


export type MutationRemoveCreatedByFromRegulationArgs = {
  input: RemoveCreatedByFromRegulationInput;
};


export type MutationRemoveCreatedByFromTenderArgs = {
  input: RemoveCreatedByFromTenderInput;
};


export type MutationRemoveDocumentsFromUserArgs = {
  input: RemoveDocumentsFromUserInput;
};


export type MutationRemoveFileFromJobPostArgs = {
  input: RemoveFileFromJobPostInput;
};


export type MutationRemoveFileFromRegulationArgs = {
  input: RemoveFileFromRegulationInput;
};


export type MutationRemoveFileFromTenderArgs = {
  input: RemoveFileFromTenderInput;
};


export type MutationRemoveMediaFromCarouselArgs = {
  input: RemoveMediaFromCarouselInput;
};


export type MutationRemoveMediaFromPublicUserArgs = {
  input: RemoveMediaFromPublicUserInput;
};


export type MutationRemoveMediaFromUserArgs = {
  input: RemoveMediaFromUserInput;
};


export type MutationRemoveMediasFromBlogArgs = {
  input: RemoveMediasFromBlogInput;
};


export type MutationRemoveMediasFromEventArgs = {
  input: RemoveMediasFromEventInput;
};


export type MutationRemoveMediasFromJobPostArgs = {
  input: RemoveMediasFromJobPostInput;
};


export type MutationRemoveMediasFromRegulationArgs = {
  input: RemoveMediasFromRegulationInput;
};


export type MutationRemoveMediasFromTenderArgs = {
  input: RemoveMediasFromTenderInput;
};


export type MutationRemoveRoleFromUserArgs = {
  input: RemoveRoleFromUserInput;
};


export type MutationSetCoverImageOnBlogArgs = {
  input: SetCoverImageOnBlogInput;
};


export type MutationSetCoverImageOnEventArgs = {
  input: SetCoverImageOnEventInput;
};


export type MutationSetCreatedByOnBlogArgs = {
  input: SetCreatedByOnBlogInput;
};


export type MutationSetCreatedByOnCancerCenterArgs = {
  input: SetCreatedByOnCancerCenterInput;
};


export type MutationSetCreatedByOnCarouselArgs = {
  input: SetCreatedByOnCarouselInput;
};


export type MutationSetCreatedByOnEventArgs = {
  input: SetCreatedByOnEventInput;
};


export type MutationSetCreatedByOnJobPostArgs = {
  input: SetCreatedByOnJobPostInput;
};


export type MutationSetCreatedByOnRegulationArgs = {
  input: SetCreatedByOnRegulationInput;
};


export type MutationSetCreatedByOnTenderArgs = {
  input: SetCreatedByOnTenderInput;
};


export type MutationSetDocumentsOnUserArgs = {
  input: SetDocumentsOnUserInput;
};


export type MutationSetFileOnJobPostArgs = {
  input: SetFileOnJobPostInput;
};


export type MutationSetFileOnRegulationArgs = {
  input: SetFileOnRegulationInput;
};


export type MutationSetFileOnTenderArgs = {
  input: SetFileOnTenderInput;
};


export type MutationSetMediaOnCarouselArgs = {
  input: SetMediaOnCarouselInput;
};


export type MutationSetMediaOnPublicUserArgs = {
  input: SetMediaOnPublicUserInput;
};


export type MutationSetMediaOnUserArgs = {
  input: SetMediaOnUserInput;
};


export type MutationSetMediasOnBlogArgs = {
  input: SetMediasOnBlogInput;
};


export type MutationSetMediasOnEventArgs = {
  input: SetMediasOnEventInput;
};


export type MutationSetMediasOnJobPostArgs = {
  input: SetMediasOnJobPostInput;
};


export type MutationSetMediasOnRegulationArgs = {
  input: SetMediasOnRegulationInput;
};


export type MutationSetMediasOnTenderArgs = {
  input: SetMediasOnTenderInput;
};


export type MutationSetRoleOnUserArgs = {
  input: SetRoleOnUserInput;
};


export type MutationUpdateBlogArgs = {
  coverImage?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  otherMedias?: InputMaybe<Array<Scalars['Upload']>>;
  updateBlogInput: BlogUpdateInput;
};


export type MutationUpdateCancerCenterArgs = {
  id: Scalars['Int'];
  updateCancerCenterInput: CancerCenterUpdateInput;
};


export type MutationUpdateCarouselArgs = {
  carouselImage?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  updateCarouselInput: CarouselUpdateInput;
};


export type MutationUpdateEventArgs = {
  coverImage?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  otherMedias?: InputMaybe<Array<Scalars['Upload']>>;
  updateEventInput: EventUpdateInput;
};


export type MutationUpdateJobPostArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  otherMedias?: InputMaybe<Array<Scalars['Upload']>>;
  updateJobPostInput: JobPostUpdateInput;
};


export type MutationUpdateRegulationArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  otherMedias?: InputMaybe<Array<Scalars['Upload']>>;
  updateRegulationInput: RegulationUpdateInput;
};


export type MutationUpdateSelfArgs = {
  input: UpdateUserInput;
  profileImage: Scalars['Upload'];
};


export type MutationUpdateTenderArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  otherMedias?: InputMaybe<Array<Scalars['Upload']>>;
  updateTenderInput: TenderUpdateInput;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export type PublicSpeakerDto = {
  __typename?: 'PublicSpeakerDTO';
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PublicSpeakerInput = {
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PublicUser = {
  __typename?: 'PublicUser';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  media?: Maybe<Media>;
};

export type PublicUserAggregateGroupBy = {
  __typename?: 'PublicUserAggregateGroupBy';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type PublicUserAvgAggregate = {
  __typename?: 'PublicUserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PublicUserCountAggregate = {
  __typename?: 'PublicUserCountAggregate';
  firstName?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['Int']>;
};

export type PublicUserEdge = {
  __typename?: 'PublicUserEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the PublicUser */
  node: PublicUser;
};

export type PublicUserMaxAggregate = {
  __typename?: 'PublicUserMaxAggregate';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type PublicUserMinAggregate = {
  __typename?: 'PublicUserMinAggregate';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type PublicUserSumAggregate = {
  __typename?: 'PublicUserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  blog?: Maybe<Blog>;
  blogAggregate: Array<BlogAggregateResponse>;
  blogs: BlogConnection;
  cancerCenter?: Maybe<CancerCenter>;
  cancerCenterAggregate: Array<CancerCenterAggregateResponse>;
  cancerCenters: CancerCenterConnection;
  carousel?: Maybe<Carousel>;
  carouselAggregate: Array<CarouselAggregateResponse>;
  carousels: CarouselConnection;
  currentConfiguration: CurrentConfiguration;
  event?: Maybe<Event>;
  eventAggregate: Array<EventAggregateResponse>;
  events: EventConnection;
  jobPost?: Maybe<JobPost>;
  jobPostAggregate: Array<JobPostAggregateResponse>;
  jobPosts: JobPostConnection;
  publicUser?: Maybe<PublicUser>;
  regulation?: Maybe<Regulation>;
  regulationAggregate: Array<RegulationAggregateResponse>;
  regulations: RegulationConnection;
  tender?: Maybe<Tender>;
  tenderAggregate: Array<TenderAggregateResponse>;
  tenders: TenderConnection;
  user?: Maybe<User>;
  userAggregate: Array<UserAggregateResponse>;
};


export type QueryBlogArgs = {
  id: Scalars['ID'];
};


export type QueryBlogAggregateArgs = {
  filter?: InputMaybe<BlogAggregateFilter>;
};


export type QueryBlogsArgs = {
  filter?: InputMaybe<BlogFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BlogSort>>;
};


export type QueryCancerCenterArgs = {
  id: Scalars['ID'];
};


export type QueryCancerCenterAggregateArgs = {
  filter?: InputMaybe<CancerCenterAggregateFilter>;
};


export type QueryCancerCentersArgs = {
  filter?: InputMaybe<CancerCenterFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<CancerCenterSort>>;
};


export type QueryCarouselArgs = {
  id: Scalars['ID'];
};


export type QueryCarouselAggregateArgs = {
  filter?: InputMaybe<CarouselAggregateFilter>;
};


export type QueryCarouselsArgs = {
  filter?: InputMaybe<CarouselFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<CarouselSort>>;
};


export type QueryEventArgs = {
  id: Scalars['ID'];
};


export type QueryEventAggregateArgs = {
  filter?: InputMaybe<EventAggregateFilter>;
};


export type QueryEventsArgs = {
  filter?: InputMaybe<EventFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<EventSort>>;
};


export type QueryJobPostArgs = {
  id: Scalars['ID'];
};


export type QueryJobPostAggregateArgs = {
  filter?: InputMaybe<JobPostAggregateFilter>;
};


export type QueryJobPostsArgs = {
  filter?: InputMaybe<JobPostFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<JobPostSort>>;
};


export type QueryPublicUserArgs = {
  id: Scalars['ID'];
};


export type QueryRegulationArgs = {
  id: Scalars['ID'];
};


export type QueryRegulationAggregateArgs = {
  filter?: InputMaybe<RegulationAggregateFilter>;
};


export type QueryRegulationsArgs = {
  filter?: InputMaybe<RegulationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<RegulationSort>>;
};


export type QueryTenderArgs = {
  id: Scalars['ID'];
};


export type QueryTenderAggregateArgs = {
  filter?: InputMaybe<TenderAggregateFilter>;
};


export type QueryTendersArgs = {
  filter?: InputMaybe<TenderFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<TenderSort>>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserAggregateArgs = {
  filter?: InputMaybe<UserAggregateFilter>;
};

export type Regulation = {
  __typename?: 'Regulation';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdBy: PublicUser;
  createdById?: Maybe<Scalars['Float']>;
  downloads: Scalars['Float'];
  enabled: Scalars['Boolean'];
  file: Media;
  fileSize: Scalars['Float'];
  fileType: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  medias?: Maybe<Array<Media>>;
  mediasAggregate: Array<RegulationMediasAggregateResponse>;
  title: Scalars['String'];
  views: Scalars['Float'];
};


export type RegulationMediasArgs = {
  filter?: InputMaybe<MediaFilter>;
  sorting?: InputMaybe<Array<MediaSort>>;
};


export type RegulationMediasAggregateArgs = {
  filter?: InputMaybe<MediaAggregateFilter>;
};

export type RegulationAggregateFilter = {
  and?: InputMaybe<Array<RegulationAggregateFilter>>;
  applicationDeadline?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdById?: InputMaybe<NumberFieldComparison>;
  downloads?: InputMaybe<NumberFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  fileSize?: InputMaybe<NumberFieldComparison>;
  fileType?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RegulationAggregateFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  views?: InputMaybe<NumberFieldComparison>;
};

export type RegulationAggregateGroupBy = {
  __typename?: 'RegulationAggregateGroupBy';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Float']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fileSize?: Maybe<Scalars['Float']>;
  fileType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Float']>;
};

export type RegulationAggregateResponse = {
  __typename?: 'RegulationAggregateResponse';
  avg?: Maybe<RegulationAvgAggregate>;
  count?: Maybe<RegulationCountAggregate>;
  groupBy?: Maybe<RegulationAggregateGroupBy>;
  max?: Maybe<RegulationMaxAggregate>;
  min?: Maybe<RegulationMinAggregate>;
  sum?: Maybe<RegulationSumAggregate>;
};

export type RegulationAvgAggregate = {
  __typename?: 'RegulationAvgAggregate';
  createdById?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

export type RegulationConnection = {
  __typename?: 'RegulationConnection';
  /** Array of nodes. */
  nodes: Array<Regulation>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type RegulationCountAggregate = {
  __typename?: 'RegulationCountAggregate';
  applicationDeadline?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  downloads?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Int']>;
  fileSize?: Maybe<Scalars['Int']>;
  fileType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
};

export type RegulationFilter = {
  and?: InputMaybe<Array<RegulationFilter>>;
  applicationDeadline?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdById?: InputMaybe<NumberFieldComparison>;
  downloads?: InputMaybe<NumberFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  fileSize?: InputMaybe<NumberFieldComparison>;
  fileType?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RegulationFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  views?: InputMaybe<NumberFieldComparison>;
};

export type RegulationInput = {
  applicationDeadline?: InputMaybe<Scalars['Timestamp']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  createdById?: InputMaybe<Scalars['Float']>;
  downloads?: InputMaybe<Scalars['Float']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  fileSize?: InputMaybe<Scalars['Float']>;
  fileType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  views?: InputMaybe<Scalars['Float']>;
};

export type RegulationMaxAggregate = {
  __typename?: 'RegulationMaxAggregate';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  fileType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Float']>;
};

export type RegulationMediasAggregateGroupBy = {
  __typename?: 'RegulationMediasAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type RegulationMediasAggregateResponse = {
  __typename?: 'RegulationMediasAggregateResponse';
  avg?: Maybe<RegulationMediasAvgAggregate>;
  count?: Maybe<RegulationMediasCountAggregate>;
  groupBy?: Maybe<RegulationMediasAggregateGroupBy>;
  max?: Maybe<RegulationMediasMaxAggregate>;
  min?: Maybe<RegulationMediasMinAggregate>;
  sum?: Maybe<RegulationMediasSumAggregate>;
};

export type RegulationMediasAvgAggregate = {
  __typename?: 'RegulationMediasAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type RegulationMediasCountAggregate = {
  __typename?: 'RegulationMediasCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type RegulationMediasMaxAggregate = {
  __typename?: 'RegulationMediasMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type RegulationMediasMinAggregate = {
  __typename?: 'RegulationMediasMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type RegulationMediasSumAggregate = {
  __typename?: 'RegulationMediasSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type RegulationMinAggregate = {
  __typename?: 'RegulationMinAggregate';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  fileType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Float']>;
};

export type RegulationSort = {
  direction: SortDirection;
  field: RegulationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RegulationSortFields {
  ApplicationDeadline = 'applicationDeadline',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Downloads = 'downloads',
  Enabled = 'enabled',
  FileSize = 'fileSize',
  FileType = 'fileType',
  Id = 'id',
  Title = 'title',
  Views = 'views'
}

export type RegulationSumAggregate = {
  __typename?: 'RegulationSumAggregate';
  createdById?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

export type RegulationUpdateInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type RemoveCoverImageFromBlogInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveCoverImageFromEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveCreatedByFromBlogInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveCreatedByFromCancerCenterInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveCreatedByFromCarouselInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveCreatedByFromEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveCreatedByFromJobPostInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveCreatedByFromRegulationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveCreatedByFromTenderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveDocumentsFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveFileFromJobPostInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveFileFromRegulationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveFileFromTenderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMediaFromCarouselInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMediaFromPublicUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMediaFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMediasFromBlogInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMediasFromEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMediasFromJobPostInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMediasFromRegulationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMediasFromTenderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveRoleFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCoverImageOnBlogInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCoverImageOnEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCreatedByOnBlogInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCreatedByOnCancerCenterInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCreatedByOnCarouselInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCreatedByOnEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCreatedByOnJobPostInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCreatedByOnRegulationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCreatedByOnTenderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetDocumentsOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetFileOnJobPostInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetFileOnRegulationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetFileOnTenderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMediaOnCarouselInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMediaOnPublicUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMediaOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMediasOnBlogInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMediasOnEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMediasOnJobPostInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMediasOnRegulationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMediasOnTenderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetRoleOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  iLike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  notILike?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  notLike?: InputMaybe<Scalars['String']>;
};

export type Tender = {
  __typename?: 'Tender';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdBy: PublicUser;
  createdById?: Maybe<Scalars['Float']>;
  downloads: Scalars['Float'];
  enabled: Scalars['Boolean'];
  file: Media;
  fileSize: Scalars['Float'];
  fileType: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  medias?: Maybe<Array<Media>>;
  mediasAggregate: Array<TenderMediasAggregateResponse>;
  title: Scalars['String'];
  views: Scalars['Float'];
};


export type TenderMediasArgs = {
  filter?: InputMaybe<MediaFilter>;
  sorting?: InputMaybe<Array<MediaSort>>;
};


export type TenderMediasAggregateArgs = {
  filter?: InputMaybe<MediaAggregateFilter>;
};

export type TenderAggregateFilter = {
  and?: InputMaybe<Array<TenderAggregateFilter>>;
  applicationDeadline?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdById?: InputMaybe<NumberFieldComparison>;
  downloads?: InputMaybe<NumberFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  fileSize?: InputMaybe<NumberFieldComparison>;
  fileType?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<TenderAggregateFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  views?: InputMaybe<NumberFieldComparison>;
};

export type TenderAggregateGroupBy = {
  __typename?: 'TenderAggregateGroupBy';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Float']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fileSize?: Maybe<Scalars['Float']>;
  fileType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Float']>;
};

export type TenderAggregateResponse = {
  __typename?: 'TenderAggregateResponse';
  avg?: Maybe<TenderAvgAggregate>;
  count?: Maybe<TenderCountAggregate>;
  groupBy?: Maybe<TenderAggregateGroupBy>;
  max?: Maybe<TenderMaxAggregate>;
  min?: Maybe<TenderMinAggregate>;
  sum?: Maybe<TenderSumAggregate>;
};

export type TenderAvgAggregate = {
  __typename?: 'TenderAvgAggregate';
  createdById?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

export type TenderConnection = {
  __typename?: 'TenderConnection';
  /** Array of nodes. */
  nodes: Array<Tender>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type TenderCountAggregate = {
  __typename?: 'TenderCountAggregate';
  applicationDeadline?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['Int']>;
  downloads?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Int']>;
  fileSize?: Maybe<Scalars['Int']>;
  fileType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
};

export type TenderFilter = {
  and?: InputMaybe<Array<TenderFilter>>;
  applicationDeadline?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdById?: InputMaybe<NumberFieldComparison>;
  downloads?: InputMaybe<NumberFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  fileSize?: InputMaybe<NumberFieldComparison>;
  fileType?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<TenderFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  views?: InputMaybe<NumberFieldComparison>;
};

export type TenderInput = {
  applicationDeadline?: InputMaybe<Scalars['Timestamp']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  createdById?: InputMaybe<Scalars['Float']>;
  downloads?: InputMaybe<Scalars['Float']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  fileSize?: InputMaybe<Scalars['Float']>;
  fileType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  views?: InputMaybe<Scalars['Float']>;
};

export type TenderMaxAggregate = {
  __typename?: 'TenderMaxAggregate';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  fileType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Float']>;
};

export type TenderMediasAggregateGroupBy = {
  __typename?: 'TenderMediasAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type TenderMediasAggregateResponse = {
  __typename?: 'TenderMediasAggregateResponse';
  avg?: Maybe<TenderMediasAvgAggregate>;
  count?: Maybe<TenderMediasCountAggregate>;
  groupBy?: Maybe<TenderMediasAggregateGroupBy>;
  max?: Maybe<TenderMediasMaxAggregate>;
  min?: Maybe<TenderMediasMinAggregate>;
  sum?: Maybe<TenderMediasSumAggregate>;
};

export type TenderMediasAvgAggregate = {
  __typename?: 'TenderMediasAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type TenderMediasCountAggregate = {
  __typename?: 'TenderMediasCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type TenderMediasMaxAggregate = {
  __typename?: 'TenderMediasMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type TenderMediasMinAggregate = {
  __typename?: 'TenderMediasMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type TenderMediasSumAggregate = {
  __typename?: 'TenderMediasSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type TenderMinAggregate = {
  __typename?: 'TenderMinAggregate';
  applicationDeadline?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  createdById?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  fileType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Float']>;
};

export type TenderSort = {
  direction: SortDirection;
  field: TenderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TenderSortFields {
  ApplicationDeadline = 'applicationDeadline',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Downloads = 'downloads',
  Enabled = 'enabled',
  FileSize = 'fileSize',
  FileType = 'fileType',
  Id = 'id',
  Title = 'title',
  Views = 'views'
}

export type TenderSumAggregate = {
  __typename?: 'TenderSumAggregate';
  createdById?: Maybe<Scalars['Float']>;
  downloads?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

export type TenderUpdateInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  carProductionYear: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  activated: Scalars['Boolean'];
  address: Scalars['String'];
  documents: Array<Media>;
  documentsAggregate: Array<UserDocumentsAggregateResponse>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idNumber: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  media?: Maybe<Media>;
  mobileNumber: Scalars['String'];
  role: UserRole;
};


export type UserDocumentsArgs = {
  filter?: InputMaybe<MediaFilter>;
  sorting?: InputMaybe<Array<MediaSort>>;
};


export type UserDocumentsAggregateArgs = {
  filter?: InputMaybe<MediaAggregateFilter>;
};

export type UserAggregateFilter = {
  address?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<UserAggregateFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  idNumber?: InputMaybe<StringFieldComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  mobileNumber?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserAggregateFilter>>;
};

export type UserAggregateGroupBy = {
  __typename?: 'UserAggregateGroupBy';
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idNumber?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
};

export type UserAggregateResponse = {
  __typename?: 'UserAggregateResponse';
  avg?: Maybe<UserAvgAggregate>;
  count?: Maybe<UserCountAggregate>;
  groupBy?: Maybe<UserAggregateGroupBy>;
  max?: Maybe<UserMaxAggregate>;
  min?: Maybe<UserMinAggregate>;
  sum?: Maybe<UserSumAggregate>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  address?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  idNumber?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['Int']>;
  mobileNumber?: Maybe<Scalars['Int']>;
};

export type UserDocumentsAggregateGroupBy = {
  __typename?: 'UserDocumentsAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type UserDocumentsAggregateResponse = {
  __typename?: 'UserDocumentsAggregateResponse';
  avg?: Maybe<UserDocumentsAvgAggregate>;
  count?: Maybe<UserDocumentsCountAggregate>;
  groupBy?: Maybe<UserDocumentsAggregateGroupBy>;
  max?: Maybe<UserDocumentsMaxAggregate>;
  min?: Maybe<UserDocumentsMinAggregate>;
  sum?: Maybe<UserDocumentsSumAggregate>;
};

export type UserDocumentsAvgAggregate = {
  __typename?: 'UserDocumentsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserDocumentsCountAggregate = {
  __typename?: 'UserDocumentsCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type UserDocumentsMaxAggregate = {
  __typename?: 'UserDocumentsMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type UserDocumentsMinAggregate = {
  __typename?: 'UserDocumentsMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type UserDocumentsSumAggregate = {
  __typename?: 'UserDocumentsSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the User */
  node: User;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idNumber?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idNumber?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['ID'];
};

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type Carousel = {
  __typename?: 'carousel';
  action: Scalars['String'];
  createdBy: PublicUser;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  media: Media;
  mediaId?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
};

export type CarouselAggregateFilter = {
  action?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<CarouselAggregateFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  mediaId?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CarouselAggregateFilter>>;
  title?: InputMaybe<StringFieldComparison>;
};

export type CarouselAggregateGroupBy = {
  __typename?: 'carouselAggregateGroupBy';
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  mediaId?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export type CarouselAggregateResponse = {
  __typename?: 'carouselAggregateResponse';
  avg?: Maybe<CarouselAvgAggregate>;
  count?: Maybe<CarouselCountAggregate>;
  groupBy?: Maybe<CarouselAggregateGroupBy>;
  max?: Maybe<CarouselMaxAggregate>;
  min?: Maybe<CarouselMinAggregate>;
  sum?: Maybe<CarouselSumAggregate>;
};

export type CarouselAvgAggregate = {
  __typename?: 'carouselAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  mediaId?: Maybe<Scalars['Float']>;
};

export type CarouselCountAggregate = {
  __typename?: 'carouselCountAggregate';
  action?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
};

export type CarouselFilter = {
  action?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<CarouselFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  mediaId?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<CarouselFilter>>;
  title?: InputMaybe<StringFieldComparison>;
};

export type CarouselInput = {
  action: Scalars['String'];
  description: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Float']>;
  mediaId?: InputMaybe<Scalars['Float']>;
  title: Scalars['String'];
};

export type CarouselMaxAggregate = {
  __typename?: 'carouselMaxAggregate';
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  mediaId?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export type CarouselMinAggregate = {
  __typename?: 'carouselMinAggregate';
  action?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  mediaId?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export type CarouselSort = {
  direction: SortDirection;
  field: CarouselSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CarouselSortFields {
  Action = 'action',
  Description = 'description',
  Enabled = 'enabled',
  Id = 'id',
  MediaId = 'mediaId',
  Title = 'title'
}

export type CarouselSumAggregate = {
  __typename?: 'carouselSumAggregate';
  id?: Maybe<Scalars['Float']>;
  mediaId?: Maybe<Scalars['Float']>;
};

export type CarouselUpdateInput = {
  action?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Float']>;
  mediaId?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type GetCancerCentersQueryVariables = Exact<{
  filter?: InputMaybe<CancerCenterFilter>;
  paging?: InputMaybe<OffsetPaging>;
}>;


export type GetCancerCentersQuery = { __typename?: 'Query', cancerCenters: { __typename?: 'CancerCenterConnection', totalCount: number, nodes: Array<{ __typename?: 'CancerCenter', name: string, address: string, county: string, designation: string, ownership: CenterOwnershipType, modalities: string, createdBy: { __typename?: 'PublicUser', firstName?: string | null, lastName?: string | null } }> } };

export type GetCountiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountiesQuery = { __typename?: 'Query', cancerCenterAggregate: Array<{ __typename?: 'CancerCenterAggregateResponse', county?: { __typename?: 'CancerCenterAggregateGroupBy', name?: string | null } | null }> };

export type GetModalitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModalitiesQuery = { __typename?: 'Query', cancerCenterAggregate: Array<{ __typename?: 'CancerCenterAggregateResponse', modality?: { __typename?: 'CancerCenterAggregateGroupBy', name?: string | null } | null }> };

export type GetBlogsQueryVariables = Exact<{
  filter?: InputMaybe<BlogFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BlogSort> | BlogSort>;
}>;


export type GetBlogsQuery = { __typename?: 'Query', blogs: { __typename?: 'BlogConnection', totalCount: number, nodes: Array<{ __typename?: 'Blog', id?: string | null, title: string, enabled: boolean, content: string, createdAt?: any | null, coverImage: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }, createdBy: { __typename?: 'PublicUser', firstName?: string | null, lastName?: string | null } }> } };

export type GetCarouselQueryVariables = Exact<{
  sorting?: InputMaybe<Array<CarouselSort> | CarouselSort>;
}>;


export type GetCarouselQuery = { __typename?: 'Query', carousels: { __typename?: 'CarouselConnection', nodes: Array<{ __typename?: 'carousel', title: string, description: string, action: string, media: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }, createdBy: { __typename?: 'PublicUser', firstName?: string | null, lastName?: string | null } }> } };

export type GetEventsQueryVariables = Exact<{
  filter?: InputMaybe<EventFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<EventSort> | EventSort>;
}>;


export type GetEventsQuery = { __typename?: 'Query', events: { __typename?: 'EventConnection', totalCount: number, nodes: Array<{ __typename?: 'Event', id?: string | null, title: string, content: string, to: any, from: any, venue: string, venueLink?: string | null, enabled: boolean, coverImage: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }, createdBy: { __typename?: 'PublicUser', firstName?: string | null, lastName?: string | null }, medias: Array<{ __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }>, speakers: Array<{ __typename?: 'PublicSpeakerDTO', firstName: string, lastName?: string | null, title: string }>, program: Array<{ __typename?: 'EventProgramDTO', from: any, to: any, title: string, speakers: Array<{ __typename?: 'PublicSpeakerDTO', firstName: string, lastName?: string | null, title: string }> }> }> } };

export type GetJobPostsQueryVariables = Exact<{
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<JobPostSort> | JobPostSort>;
  filter?: InputMaybe<JobPostFilter>;
}>;


export type GetJobPostsQuery = { __typename?: 'Query', jobPosts: { __typename?: 'JobPostConnection', totalCount: number, nodes: Array<{ __typename?: 'JobPost', id?: string | null, enabled: boolean, title: string, position: string, location: string, applicationDeadline?: any | null, createdAt?: any | null, file: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }, medias?: Array<{ __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }> | null, createdBy: { __typename?: 'PublicUser', firstName?: string | null, lastName?: string | null } }> } };

export type GetRegulationsQueryVariables = Exact<{
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<RegulationSort> | RegulationSort>;
  filter?: InputMaybe<RegulationFilter>;
}>;


export type GetRegulationsQuery = { __typename?: 'Query', regulations: { __typename?: 'RegulationConnection', totalCount: number, nodes: Array<{ __typename?: 'Regulation', id?: string | null, enabled: boolean, title: string, fileSize: number, fileType: string, downloads: number, applicationDeadline?: any | null, createdAt?: any | null, file: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }, medias?: Array<{ __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }> | null, createdBy: { __typename?: 'PublicUser', firstName?: string | null, lastName?: string | null } }> } };

export type GetSingleBlogQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleBlogQuery = { __typename?: 'Query', blog?: { __typename?: 'Blog', title: string, content: string, enabled: boolean, coverImage: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }, createdBy: { __typename?: 'PublicUser', firstName?: string | null }, medias?: Array<{ __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }> | null } | null };

export type GetSingleEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleEventQuery = { __typename?: 'Query', event?: { __typename?: 'Event', title: string, content: string, from: any, to: any, venue: string, venueLink?: string | null, enabled: boolean, canRegister: boolean, coverImage: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }, createdBy: { __typename?: 'PublicUser', firstName?: string | null }, medias: Array<{ __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }>, speakers: Array<{ __typename?: 'PublicSpeakerDTO', firstName: string, lastName?: string | null, title: string }>, program: Array<{ __typename?: 'EventProgramDTO', from: any, to: any, title: string, speakers: Array<{ __typename?: 'PublicSpeakerDTO', firstName: string, lastName?: string | null, title: string }> }> } | null };

export type GetTendersQueryVariables = Exact<{
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<TenderSort> | TenderSort>;
  filter?: InputMaybe<TenderFilter>;
}>;


export type GetTendersQuery = { __typename?: 'Query', tenders: { __typename?: 'TenderConnection', totalCount: number, nodes: Array<{ __typename?: 'Tender', id?: string | null, enabled: boolean, title: string, fileSize: number, fileType: string, downloads: number, applicationDeadline?: any | null, createdAt?: any | null, file: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }, medias?: Array<{ __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> }> | null, createdBy: { __typename?: 'PublicUser', firstName?: string | null, lastName?: string | null } }> } };

export type SubmitFeedbackMutationVariables = Exact<{
  fullNames: Scalars['String'];
  email: Scalars['String'];
  content: Scalars['String'];
  token: Scalars['String'];
}>;


export type SubmitFeedbackMutation = { __typename?: 'Mutation', newFeedback: boolean };


export const GetCancerCentersDocument = gql`
    query GetCancerCenters($filter: CancerCenterFilter, $paging: OffsetPaging) {
  cancerCenters(filter: $filter, paging: $paging) {
    nodes {
      name
      address
      county
      designation
      ownership
      modalities
      createdBy {
        firstName
        lastName
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetCancerCentersQuery__
 *
 * To run a query within a React component, call `useGetCancerCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCancerCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCancerCentersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetCancerCentersQuery(baseOptions?: Apollo.QueryHookOptions<GetCancerCentersQuery, GetCancerCentersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCancerCentersQuery, GetCancerCentersQueryVariables>(GetCancerCentersDocument, options);
      }
export function useGetCancerCentersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCancerCentersQuery, GetCancerCentersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCancerCentersQuery, GetCancerCentersQueryVariables>(GetCancerCentersDocument, options);
        }
export type GetCancerCentersQueryHookResult = ReturnType<typeof useGetCancerCentersQuery>;
export type GetCancerCentersLazyQueryHookResult = ReturnType<typeof useGetCancerCentersLazyQuery>;
export type GetCancerCentersQueryResult = Apollo.QueryResult<GetCancerCentersQuery, GetCancerCentersQueryVariables>;
export const GetCountiesDocument = gql`
    query GetCounties {
  cancerCenterAggregate {
    county: groupBy {
      name: county
    }
  }
}
    `;

/**
 * __useGetCountiesQuery__
 *
 * To run a query within a React component, call `useGetCountiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountiesQuery, GetCountiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountiesQuery, GetCountiesQueryVariables>(GetCountiesDocument, options);
      }
export function useGetCountiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountiesQuery, GetCountiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountiesQuery, GetCountiesQueryVariables>(GetCountiesDocument, options);
        }
export type GetCountiesQueryHookResult = ReturnType<typeof useGetCountiesQuery>;
export type GetCountiesLazyQueryHookResult = ReturnType<typeof useGetCountiesLazyQuery>;
export type GetCountiesQueryResult = Apollo.QueryResult<GetCountiesQuery, GetCountiesQueryVariables>;
export const GetModalitiesDocument = gql`
    query GetModalities {
  cancerCenterAggregate {
    modality: groupBy {
      name: modalities
    }
  }
}
    `;

/**
 * __useGetModalitiesQuery__
 *
 * To run a query within a React component, call `useGetModalitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModalitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModalitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModalitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetModalitiesQuery, GetModalitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModalitiesQuery, GetModalitiesQueryVariables>(GetModalitiesDocument, options);
      }
export function useGetModalitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModalitiesQuery, GetModalitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModalitiesQuery, GetModalitiesQueryVariables>(GetModalitiesDocument, options);
        }
export type GetModalitiesQueryHookResult = ReturnType<typeof useGetModalitiesQuery>;
export type GetModalitiesLazyQueryHookResult = ReturnType<typeof useGetModalitiesLazyQuery>;
export type GetModalitiesQueryResult = Apollo.QueryResult<GetModalitiesQuery, GetModalitiesQueryVariables>;
export const GetBlogsDocument = gql`
    query GetBlogs($filter: BlogFilter, $paging: OffsetPaging, $sorting: [BlogSort!]) {
  blogs(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      title
      enabled
      content
      coverImage {
        thumbnails
        addresses
      }
      createdAt
      createdBy {
        firstName
        lastName
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetBlogsQuery__
 *
 * To run a query within a React component, call `useGetBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetBlogsQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogsQuery, GetBlogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogsQuery, GetBlogsQueryVariables>(GetBlogsDocument, options);
      }
export function useGetBlogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogsQuery, GetBlogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogsQuery, GetBlogsQueryVariables>(GetBlogsDocument, options);
        }
export type GetBlogsQueryHookResult = ReturnType<typeof useGetBlogsQuery>;
export type GetBlogsLazyQueryHookResult = ReturnType<typeof useGetBlogsLazyQuery>;
export type GetBlogsQueryResult = Apollo.QueryResult<GetBlogsQuery, GetBlogsQueryVariables>;
export const GetCarouselDocument = gql`
    query GetCarousel($sorting: [carouselSort!]) {
  carousels(filter: {enabled: {is: true}}, sorting: $sorting) {
    nodes {
      title
      description
      action
      media {
        thumbnails
        addresses
      }
      createdBy {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetCarouselQuery__
 *
 * To run a query within a React component, call `useGetCarouselQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarouselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarouselQuery({
 *   variables: {
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetCarouselQuery(baseOptions?: Apollo.QueryHookOptions<GetCarouselQuery, GetCarouselQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarouselQuery, GetCarouselQueryVariables>(GetCarouselDocument, options);
      }
export function useGetCarouselLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarouselQuery, GetCarouselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarouselQuery, GetCarouselQueryVariables>(GetCarouselDocument, options);
        }
export type GetCarouselQueryHookResult = ReturnType<typeof useGetCarouselQuery>;
export type GetCarouselLazyQueryHookResult = ReturnType<typeof useGetCarouselLazyQuery>;
export type GetCarouselQueryResult = Apollo.QueryResult<GetCarouselQuery, GetCarouselQueryVariables>;
export const GetEventsDocument = gql`
    query GetEvents($filter: EventFilter, $paging: OffsetPaging, $sorting: [EventSort!]) {
  events(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      title
      content
      to
      from
      venue
      venueLink
      enabled
      coverImage {
        thumbnails
        addresses
      }
      createdBy {
        firstName
        lastName
      }
      medias {
        thumbnails
        addresses
      }
      speakers {
        firstName
        lastName
        title
      }
      program {
        from
        to
        title
        speakers {
          firstName
          lastName
          title
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const GetJobPostsDocument = gql`
    query GetJobPosts($paging: OffsetPaging, $sorting: [JobPostSort!], $filter: JobPostFilter) {
  jobPosts(paging: $paging, sorting: $sorting, filter: $filter) {
    nodes {
      id
      enabled
      title
      position
      location
      applicationDeadline
      file {
        thumbnails
        addresses
      }
      medias {
        thumbnails
        addresses
      }
      createdAt
      createdBy {
        firstName
        lastName
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetJobPostsQuery__
 *
 * To run a query within a React component, call `useGetJobPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobPostsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetJobPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetJobPostsQuery, GetJobPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobPostsQuery, GetJobPostsQueryVariables>(GetJobPostsDocument, options);
      }
export function useGetJobPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobPostsQuery, GetJobPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobPostsQuery, GetJobPostsQueryVariables>(GetJobPostsDocument, options);
        }
export type GetJobPostsQueryHookResult = ReturnType<typeof useGetJobPostsQuery>;
export type GetJobPostsLazyQueryHookResult = ReturnType<typeof useGetJobPostsLazyQuery>;
export type GetJobPostsQueryResult = Apollo.QueryResult<GetJobPostsQuery, GetJobPostsQueryVariables>;
export const GetRegulationsDocument = gql`
    query GetRegulations($paging: OffsetPaging, $sorting: [RegulationSort!], $filter: RegulationFilter) {
  regulations(paging: $paging, sorting: $sorting, filter: $filter) {
    nodes {
      id
      enabled
      title
      fileSize
      fileType
      downloads
      applicationDeadline
      file {
        thumbnails
        addresses
      }
      medias {
        thumbnails
        addresses
      }
      createdAt
      createdBy {
        firstName
        lastName
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetRegulationsQuery__
 *
 * To run a query within a React component, call `useGetRegulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegulationsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetRegulationsQuery(baseOptions?: Apollo.QueryHookOptions<GetRegulationsQuery, GetRegulationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegulationsQuery, GetRegulationsQueryVariables>(GetRegulationsDocument, options);
      }
export function useGetRegulationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegulationsQuery, GetRegulationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegulationsQuery, GetRegulationsQueryVariables>(GetRegulationsDocument, options);
        }
export type GetRegulationsQueryHookResult = ReturnType<typeof useGetRegulationsQuery>;
export type GetRegulationsLazyQueryHookResult = ReturnType<typeof useGetRegulationsLazyQuery>;
export type GetRegulationsQueryResult = Apollo.QueryResult<GetRegulationsQuery, GetRegulationsQueryVariables>;
export const GetSingleBlogDocument = gql`
    query GetSingleBlog($id: ID!) {
  blog(id: $id) {
    title
    content
    enabled
    coverImage {
      thumbnails
      addresses
    }
    createdBy {
      firstName
    }
    medias {
      thumbnails
      addresses
    }
  }
}
    `;

/**
 * __useGetSingleBlogQuery__
 *
 * To run a query within a React component, call `useGetSingleBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleBlogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleBlogQuery(baseOptions: Apollo.QueryHookOptions<GetSingleBlogQuery, GetSingleBlogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleBlogQuery, GetSingleBlogQueryVariables>(GetSingleBlogDocument, options);
      }
export function useGetSingleBlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleBlogQuery, GetSingleBlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleBlogQuery, GetSingleBlogQueryVariables>(GetSingleBlogDocument, options);
        }
export type GetSingleBlogQueryHookResult = ReturnType<typeof useGetSingleBlogQuery>;
export type GetSingleBlogLazyQueryHookResult = ReturnType<typeof useGetSingleBlogLazyQuery>;
export type GetSingleBlogQueryResult = Apollo.QueryResult<GetSingleBlogQuery, GetSingleBlogQueryVariables>;
export const GetSingleEventDocument = gql`
    query GetSingleEvent($id: ID!) {
  event(id: $id) {
    title
    content
    from
    to
    venue
    venueLink
    enabled
    coverImage {
      thumbnails
      addresses
    }
    createdBy {
      firstName
    }
    medias {
      thumbnails
      addresses
    }
    speakers {
      firstName
      lastName
      title
    }
    canRegister
    program {
      from
      to
      title
      speakers {
        firstName
        lastName
        title
      }
    }
  }
}
    `;

/**
 * __useGetSingleEventQuery__
 *
 * To run a query within a React component, call `useGetSingleEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleEventQuery(baseOptions: Apollo.QueryHookOptions<GetSingleEventQuery, GetSingleEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleEventQuery, GetSingleEventQueryVariables>(GetSingleEventDocument, options);
      }
export function useGetSingleEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleEventQuery, GetSingleEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleEventQuery, GetSingleEventQueryVariables>(GetSingleEventDocument, options);
        }
export type GetSingleEventQueryHookResult = ReturnType<typeof useGetSingleEventQuery>;
export type GetSingleEventLazyQueryHookResult = ReturnType<typeof useGetSingleEventLazyQuery>;
export type GetSingleEventQueryResult = Apollo.QueryResult<GetSingleEventQuery, GetSingleEventQueryVariables>;
export const GetTendersDocument = gql`
    query GetTenders($paging: OffsetPaging, $sorting: [TenderSort!], $filter: TenderFilter) {
  tenders(paging: $paging, sorting: $sorting, filter: $filter) {
    nodes {
      id
      enabled
      title
      fileSize
      fileType
      downloads
      applicationDeadline
      file {
        thumbnails
        addresses
      }
      medias {
        thumbnails
        addresses
      }
      createdAt
      createdBy {
        firstName
        lastName
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetTendersQuery__
 *
 * To run a query within a React component, call `useGetTendersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTendersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTendersQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTendersQuery(baseOptions?: Apollo.QueryHookOptions<GetTendersQuery, GetTendersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTendersQuery, GetTendersQueryVariables>(GetTendersDocument, options);
      }
export function useGetTendersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTendersQuery, GetTendersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTendersQuery, GetTendersQueryVariables>(GetTendersDocument, options);
        }
export type GetTendersQueryHookResult = ReturnType<typeof useGetTendersQuery>;
export type GetTendersLazyQueryHookResult = ReturnType<typeof useGetTendersLazyQuery>;
export type GetTendersQueryResult = Apollo.QueryResult<GetTendersQuery, GetTendersQueryVariables>;
export const SubmitFeedbackDocument = gql`
    mutation SubmitFeedback($fullNames: String!, $email: String!, $content: String!, $token: String!) {
  newFeedback(
    feedbackInput: {fullNames: $fullNames, email: $email, content: $content, token: $token}
  )
}
    `;
export type SubmitFeedbackMutationFn = Apollo.MutationFunction<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;

/**
 * __useSubmitFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFeedbackMutation, { data, loading, error }] = useSubmitFeedbackMutation({
 *   variables: {
 *      fullNames: // value for 'fullNames'
 *      email: // value for 'email'
 *      content: // value for 'content'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSubmitFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>(SubmitFeedbackDocument, options);
      }
export type SubmitFeedbackMutationHookResult = ReturnType<typeof useSubmitFeedbackMutation>;
export type SubmitFeedbackMutationResult = Apollo.MutationResult<SubmitFeedbackMutation>;
export type SubmitFeedbackMutationOptions = Apollo.BaseMutationOptions<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;