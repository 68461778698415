import React from "react";

export const UserRoles = {
    ADMIN: "ADMIN",
    SUPER: "SUPER",
    OWNER: "OWNER",
    AGENT: "AGENT",
    USER: "USER",
    PUBLIC: "PUBLIC",
}

Number.prototype.formatCurrency = function (n = 0, x = 3, s = ',', c = '.') {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
String.prototype.toCamelCase = function (removeSpaces = false) {
    return this.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
String.prototype.toSentenceCase = function (removeSpaces = false) {
    return this.charAt(0).toUpperCase() + this.substr(1, this.length).toLowerCase();
}
String.prototype.trimPath = function () {
    return this.split("/").pop();
}
String.prototype.ellipsizeMiddle = function (n = 3) {
    return this.substr(0, n) + (this.length > n ? '...' : '') + (this.length > n * 2 ? this.substr(this.length - n, n) : '');
};
Array.prototype.count = function (filterMethod) {
    return this.reduce((count, item) => filterMethod(item) ? count + 1 : count, 0);
}
export const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
})
export const generateColor = function () {
    return '#' + Math.random().toString(16).substr(-6);
}
