import React, {Fragment, useState, useEffect, useCallback} from 'react';
import "./index.css";
import {TextToSpeech, Positions, Sizes} from "tts-react";
import moment from "moment";
import {BlogSortFields, EventSortFields, SortDirection, TenderSortFields, useGetBlogsQuery, useGetEventsQuery, useGetTendersLazyQuery} from "../../../graphql/generated/ncikenya-schema";
import ReactPaginate from "react-paginate";
import Accesibility from "../../accessibility";
import {Col, Row} from "react-bootstrap";
import {Icon} from "semantic-ui-react";
import {FileIcon, defaultStyles} from 'react-file-icon';
import Pagination from "rc-pagination";
import {dateTimeFormat} from "../../../config/utils";

const News = (props) => {


    const [tenders, setTenders] = useState([])
    const [popularEvents, setPopularEvents] = useState([])
    const [totalCount, setTotalCount] = useState()
    const [searchKeyword, setSearchKeyword] = useState()
    const [tenderSortField, setTenderSortField] = useState(TenderSortFields.Id)
    const [tenderSortDirection, setTenderSortFDirection] = useState(SortDirection.Desc)
    const [paging, setPaging] = useState({
        offset: 0,
        limit: 10
    })
    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button><i className="fa fa-angle-double-left"></i></button>;
        }
        if (type === 'next') {
            return <button><i className="fa fa-angle-double-right"></i></button>;
        }
        return originalElement;
    }

    const [getTenders] = useGetTendersLazyQuery({
        variables: {
            sorting: {
                direction: tenderSortDirection,
                field: tenderSortField
            },
            paging: paging,
            filter:{
                enabled:{
                    is:true
                },
                title:!searchKeyword?{}:{
                    like:`%${searchKeyword}%`
                }
            }
        },
        onCompleted: (results) => {
            setTenders(results.tenders.nodes)
            setTotalCount(results.tenders.totalCount)

        }
    })

    useEffect(() => {
        getTenders()
    }, [paging])

    return (
        <Fragment>
            <Accesibility>
                <section className="blog-wrapper news-wrapper section-padding">
                    <div className="container">
                        <div className="single-sidebar-widget mb-3">
                            <div className="search_widget">
                                <form className={'d-flex w-100'} action="#">
                                    <div className={'d-flex flex-wrap flex-grow-1'}>
                                        <input type="text" placeholder="Search your keyword..." onChange={(e) => {
                                            setSearchKeyword(e.target.value.length > 0 ? `%${e.target.value}%` : undefined)
                                        }}/>
                                        <div className="file-filter pr-3 d-flex">
                                            <Col className="single-personal-info">
                                                <label htmlFor="paddress">Filter by <span></span></label>
                                                <div className="form-group">
                                                    <select className="custom-select" onChange={(e) => {
                                                        // setSelectedCounty(e.target.value=='Any'?undefined:e.target.value)
                                                    }} required>
                                                        <option>Any</option>
                                                        {Object.keys(tenders.length > 0 ? tenders[0] : {}).map(county => {
                                                            return <option value={county}>{county}</option>
                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback"> Invalid location selected
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="single-personal-info">
                                                <label htmlFor="paddress">Order by <span></span></label>
                                                <div className="form-group">
                                                    <select className="custom-select" onChange={(e) => {
                                                        // setSelectedCounty(e.target.value=='Any'?undefined:e.target.value)
                                                    }} required>
                                                        <option>Any</option>

                                                        {Object.keys(SortDirection).map(county => {
                                                            return <option value={county}>{county}</option>
                                                        })}
                                                    </select>
                                                    <div className="invalid-feedback"> Invalid location selected
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                    <button className={'p-2'} type="submit"><i className="fal fa-filter"></i> Filter</button>
                                </form>
                            </div>
                        </div>
                        <Col className="row">
                            {
                                tenders.map((tender) => {
                                    return <Col md={12}>
                                        <div className={'m-1 p-2 d-flex flex-row'} style={{
                                            backgroundColor: "rgba(128,128,128,0.06)",
                                            boxShadow:"0px 0px 7px 1px #0000001f"
                                        }}>
                                            <div style={{width: "50px"}}><FileIcon extension="pdf" {...defaultStyles.pdf}  /></div>
                                            <div className={' pl-3 d-flex flex-column justify-content-start align-items-start'} style={{width:"100%"}}>
                                                <Row  style={{width:"100%"}}>
                                                    <Col md={8} style={{
                                                        color: "#bc2227"
                                                    }}><p className={' font-weight-bold'}>{tender.title}</p></Col>
                                                    <Col md={4} style={{
                                                    }}>
                                                        <p  style={{
                                                        fontSize:12
                                                    }} >Deadline: {tender?.applicationDeadline??'-'}</p>
                                                    </Col>
                                                </Row>
                                                {
                                                    (tender?.file?.addresses??[]).map(address=><a style={{
                                                        fontSize:14,
                                                        fontWeight:'bold'
                                                    }}  href={`${process.env.REACT_APP_BACKEND_ENDPOINT}/${encodeURI(address)}`}>{address.trimPath()}</a>)
                                                }
                                                <div className={'d-flex flex-row justify-content-start align-items-start'}>
                                                    <p style={{
                                                        fontSize:12
                                                    }} className={'mr-3'}>{tender.fileSize / 1000} MB</p>
                                                    <p style={{
                                                        fontSize:12
                                                    }} className={'mr-3'}>{tender.fileType.toLowerCase()}</p>
                                                    <p  style={{
                                                        fontSize:12
                                                    }} >Created On: {(new Intl.DateTimeFormat('en-US', {
                                                        day: '2-digit',
                                                        month:"long",
                                                        year:'numeric',
                                                    })).format(tender?.createdAt)??'-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                })
                            }
                        </Col>
                    </div>
                    <Pagination
                        total={totalCount}
                        defaultPageSize={paging.offset}
                        pageSize={paging.limit}
                        current={paging.offset+1}
                        pageSizeOptions={[5,10,25,50,100]}
                        onChange={(current, pageSize)=>{
                            setPaging({
                                offset: current-1,
                                limit: pageSize
                            })
                        }}
                        className="pagination-data m-5"
                        showTotal={(total, range) => <div>{`Showing ${range[0]}-${range[1]} of ${total}`}</div>}
                        showSizeChanger={true}
                        itemRender={PrevNextArrow}
                    />
                </section>
            </Accesibility>
        </Fragment>
    );
}

export default News;
