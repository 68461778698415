import React, {Fragment, useState, useEffect, useCallback} from 'react';
import "./index.css";
import MetisMenu from "@metismenu/react";
import 'metismenujs/dist/metismenujs.css';
import {Link, NavLink} from "react-router-dom";
import ContentMenu from "../content-menu";
import {useSpeechSynthesis} from "react-speech-kit";


const Menu = ({subMenu, pageTitle, active, eventTime, setSelectedTab, hideMainMenu}) => {
    const {speak} = useSpeechSynthesis();
    let [isMenuOpen, setIsMenuOpen] = useState(false);

    // useEffect(()=>{
    //     window.document.querySelectorAll("a").forEach(element => {
    //         element.addEventListener('mouseenter', (e) => {
    //             console.log(e)
    //             speak({text: e.target?.ariaLabel ??e.target?.innerText})
    //         })
    //     })
    // },[])

    return (
        <Fragment>
            <header className="header-wrap header-box-style header-1">
                {!hideMainMenu && <>
                <div className="col-12 pr-0 pl-0">
                    <div className="box-wrap top-box-wrap">
                        <div className="menu-wrap d-flex align-items-center justify-content-around">
                            <div className="top-menu main-menu">
                                <ul className={"d-flex"}>
                                    {/*<li className={active == 'about' ? "active" : ""}><a aria-label={"About N C I Kenya"} href="/about">About</a>*/}
                                    {/*    <ul className="sub-menu">*/}
                                    {/*        <li><a href="/about#mandate">Mandate</a></li>*/}
                                    {/*        <li><a href="/about#mission">Mission, Vision & Values</a></li>*/}
                                    {/*        <li><a href="/about#charter">Service charter</a></li>*/}
                                    {/*        <li><a href="/about#governance">Governance structure</a></li>*/}
                                    {/*        <li><a href="/about#achievements">Achievements</a></li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}
                                    <li className={active == 'contacts' ? "active" : ""}><a href="/contacts">Our
                                        Contacts</a></li>
                                    <li className={active == 'nacare' ? "active" : ""}><a href="https://nacare.ncikenya.go.ke" target="_blank" rel="noopener noreferrer">NaCaRe-KE</a></li>
                                    {/*<li className={active == 'portal' ? "active" : ""}><a href="https://portal.ncikenya.go.ke" target="_blank" rel="noopener noreferrer">NCI-K Portal</a></li>*/}
                                    <li className={active == 'news' ? "active" : ""}><a href="/news-and-events">News &
                                        Events</a>
                                        <ul className="sub-menu">
                                            <li><a href="/news-and-events#events">Events</a></li>
                                            <li><a href="/news-and-events#blog">News & Blog</a></li>
                                        </ul>
                                    </li>
                                    <li className={active == 'faq' ? "active" : ""}><a href="/faq">FAQ</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="row align-items-center mr-0 ml-0">
                        <div className="col-12 p-0 d-lg-none d-block d-none-mobile">
                            <div className="top-bar text-right d-flex w-100 justify-content-end align-items-center">
                                <div>
                                    <a href="mailto:support@ncikenya.go.ke"><i className="fal fa-envelope"></i>support@ncikenya.go.ke</a>
                                    <a href="tel:+254795012568"><i className="fal fa-phone"></i>(+254) (0) 795 012 568</a>
                                </div>
                                <a href="https://portal.ncikenya.go.ke" target="_blank" rel="noopener noreferrer" className="d-btn">Visit NCI-K Portal</a>
                            </div>
                        </div>
                        <div className="d-flex flex-row col-6 col-lg-12 pr-0 pl-0">
                            <div className="col-xl-2 col-6 col-lg-3 pr-0 pl-0 logo-wrapper">
                                <div className="logo h-100">
                                    <a href="/"><img className={"p-2"} src={require("../../../assets/img/logo.png")}
                                                     alt="NCI Kenya"/></a>
                                </div>
                            </div>
                            <div className="box-wrap pr-0 col-xl-10 pl-lg-0 col-lg-9 d-none d-lg-block">
                                <div className="top-bar text-right d-flex w-100 justify-content-end align-items-center">
                                    <div>
                                        <a href="mailto:support@ncikenya.go.ke"><i className="fal fa-envelope"></i>support@ncikenya.go.ke</a>
                                        <a href="tel:+254795012568"><i className="fal fa-phone"></i>(+254) (0) 795 012 568</a>
                                    </div>
                                    <a href="https://portal.ncikenya.go.ke" target="_blank" rel="noopener noreferrer" className="d-btn">Visit NCI-K Portal</a>
                                </div>
                                <div className="menu-wrap d-flex align-items-center justify-content-between">
                                    <div className="main-menu">
                                        <ul>
                                            <li className={active == 'home' ? "active" : ""}><a href="/">Home</a>
                                            </li>
                                            <li className={active == 'about' ? "active" : ""}><a href="/about">About Us</a>
                                                <ul className="sub-menu">
                                                    <li><a href="/about#mission">Mission, Vision & Values</a></li>
                                                    <li><a href="/about#charter">Service charter</a></li>
                                                    <li><a href="/about#board-of-directors">Governance structure</a>
                                                        <ul className="sub-menu">
                                                            <li><a href="/about#organization-structure">Organization structure</a></li>
                                                            <li><a href="/about#board-of-directors">Board of Directors</a></li>
                                                            <li><a href="/about#directorate">Directorate</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="/about#achievements">Achievements</a></li>
                                                </ul>
                                                {/*<ul className="sub-menu">*/}
                                                {/*    <li><a href="/cancer#what">What is Cancer</a></li>*/}
                                                {/*    <li><a href="/cancer#types">Types of Cancer</a></li>*/}
                                                {/*    <li><a href="/cancer#diagnosis">Cancer Diagnosis</a></li>*/}
                                                {/*    <li><a href="/awareness#prevention">Cancer Prevention</a></li>*/}
                                                {/*</ul>*/}
                                            </li>
                                            <li className={active == 'what-we-do' ? "active" : ""}><a href="/what-we-do">What we do</a>
                                                <ul className="sub-menu">
                                                    <li><a href="/what-we-do#mandate">Mandate</a></li>
                                                </ul>
                                            </li>
                                            <li className={active == 'cancer-awareness' ? "active" : ""}><a href="/cancer-awareness">Cancer Awareness</a>
                                                <ul className="sub-menu">
                                                    <li><a href="/cancer-awareness#risk">Your cancer risk</a></li>
                                                    <li><a href="/cancer-awareness#screening">Cancer screening</a></li>
                                                    <li><a href="/cancer-awareness#bodymap">Interactive body map</a></li>
                                                </ul>
                                            </li>
                                            <li className={active == 'research-policies' ? "active" : ""}><a
                                                href="/research">Research & Statistics</a>
                                                <ul className="sub-menu">
                                                    <li><a href="/research#research">Research</a></li>
                                                    <li><a href="/research#statistics">Statistics</a></li>
                                                </ul>
                                            </li>
                                            <li className={active == 'resources' ? "active" : ""}><a
                                                href="/resources">Resources</a>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <a href="/resources#regulations">Regulations</a>
                                                    </li>
                                                    <li>
                                                        <a href="/resources#policies">Policies & Guidelines</a>
                                                    </li>
                                                    <li><a href="/resources#posters">Posters & Fliers</a></li>
                                                </ul>
                                            </li>
                                            <li className={active == 'media' ? "active" : ""}><a
                                                href="/media">Media</a>
                                            </li>
                                            <li className={active == 'work-with-us' ? "active" : ""}>
                                                <a href="#">Work with Us</a>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <a href="/careers">Careers</a>
                                                    </li>
                                                    <li>
                                                        <a href="/procurement">Procurement</a>
                                                        <ul className="sub-menu">
                                                            {/*<li>*/}
                                                            {/*    <a href="/procurement#registration">Registration</a>*/}
                                                            {/*</li>*/}
                                                            <li>
                                                                <a href="/procurement#tenders">Tenders</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    {/*<li>*/}
                                                    {/*    <a href="/career">Career</a>*/}
                                                    {/*    <ul className="sub-menu">*/}
                                                    {/*        <li>*/}
                                                    {/*            <a href="/career#vacancies">Vacancies</a>*/}
                                                    {/*        </li>*/}
                                                    {/*    </ul>*/}
                                                    {/*</li>*/}
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="social-link">
                                        <a href="https://web.facebook.com/profile.php?id=61550108184629"><i className="fab fa-facebook-f"></i></a>
                                        <a href="https://twitter.com/NCIKenya"><i className="fab fa-twitter"></i></a>
                                        {/*<a href="#"><i className="fab fa-linkedin-in"></i></a>*/}
                                        <a href="https://www.youtube.com/@nationalcancerinstituteofk8933"><i className="fab fa-youtube"></i></a>
                                        <a href="https://www.instagram.com/nci_kenya/"><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 d-lg-none d-block pl-0">
                            <div className="mobile-nav-wrap">
                                <div id="hamburger" onClick={() => setIsMenuOpen(true)}>
                                    <i className="fal fa-bars"></i>
                                </div>

                                <div className={"mobile-nav" + (isMenuOpen ? " show" : "")}>
                                    <button type="button" className="close-nav" onClick={() => setIsMenuOpen(false)}>
                                        <i className="fal fa-times-circle"></i>
                                    </button>
                                    <nav className="sidebar-nav">
                                        <MetisMenu id="mobile-menu">

                                            <li className={active == 'home' ? "active" : ""}><Link to="/">Home</Link>
                                            </li>
                                            <li className={active == 'learn' ? "active" : ""}>
                                                <Link className={"has-arrow"} to="/cancer">About Us</Link>
                                                <ul className="sub-menu">
                                                    <li><NavLink to="/about#mission">Mission, Vision & Values</NavLink></li>
                                                    <li><NavLink to="/about#charter">Service charter</NavLink></li>
                                                    <li><NavLink to="/about#board-of-directors">Governance structure</NavLink>
                                                        <ul className="sub-menu">
                                                            <li><NavLink to="/about#board-of-directors">Board of Directors</NavLink></li>
                                                            <li><NavLink to="/about#directorate">Directorate</NavLink></li>
                                                        </ul>
                                                    </li>
                                                    {/*<li><NavLink to="/about#achievements">Achievements</NavLink></li>*/}
                                                </ul>
                                                {/*<ul className="sub-menu">*/}
                                                {/*    <li><NavLink to="/cancer#what">What is Cancer</NavLink></li>*/}
                                                {/*    <li><NavLink to="/cancer#types">Types of Cancer</NavLink></li>*/}
                                                {/*    <li><NavLink to="/cancer#diagnosis">Cancer Diagnosis</NavLink></li>*/}
                                                {/*</ul>*/}
                                            </li>
                                            <li className={active == 'what-we-do' ? "active" : ""}>
                                                <Link className={"has-arrow"} to="/what-we-do">What we do</Link>
                                                <ul className="sub-menu">
                                                    <li><NavLink to="/what-we-do#mandate">Mandate</NavLink></li>
                                                </ul>
                                                {/*<ul className="sub-menu">*/}
                                                {/*    <li><NavLink to="/awareness#risk">Your cancer risk</NavLink></li>*/}
                                                {/*    <li><NavLink to="/awareness#screening">Cancer screening</NavLink></li>*/}
                                                {/*    <li><NavLink to="/awareness#bodymap">Interactive body map</NavLink></li>*/}
                                                {/*    <li><NavLink to="/awareness#prevention">Cancer Prevention</NavLink></li>*/}
                                                {/*</ul>*/}
                                            </li>
                                            <li className={active == 'cancer-awareness' ? "active" : ""}>
                                                <Link className={"has-arrow"} href="/cancer-awareness">Cancer Awareness</Link>
                                                <ul className="sub-menu">
                                                    <li><NavLink to="/cancer-awareness#risk">Your cancer risk</NavLink></li>
                                                    <li><NavLink to="/cancer-awareness#screening">Cancer screening</NavLink></li>
                                                    <li><NavLink to="/cancer-awareness#bodymap">Interactive body map</NavLink></li>
                                                </ul>
                                            </li>
                                            <li className={active == 'research-policies' ? "active" : ""}>
                                                <Link className={"has-arrow"} href="/research">Research & Statistics</Link>
                                                <ul className="sub-menu">
                                                    <li><NavLink to="/research#research">Research</NavLink></li>
                                                    <li><NavLink to="/research#statistics">Statistics</NavLink></li>
                                                </ul>
                                            </li>
                                            <li className={active == 'resources' ? "active" : ""}>
                                                <Link className={"has-arrow"} href="/resources">Resources</Link>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <NavLink to="/resources#regulations">Regulations</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/resources#policies">Policies & Guidelines</NavLink>
                                                    </li>
                                                    <li><NavLink to="/resources#posters">Posters & Fliers</NavLink></li>
                                                </ul>
                                            </li>
                                            <li className={active == 'media' ? "active" : ""}><Link href="/media">Media</Link></li>
                                            <li className={active == 'resources' ? "active" : ""}>
                                                <Link className={"has-arrow"} href="#">Work With Us</Link>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <NavLink to="/careers">Careers</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/procurement">Procurement</NavLink>
                                                        <ul className="sub-menu">
                                                            <li><NavLink to="/procurement#tenders">Tender</NavLink></li>
                                                            {/*<li>*/}
                                                            {/*    <NavLink to="/procurement#Careers">Careers</NavLink>*/}
                                                            {/*</li>*/}
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </MetisMenu>
                                    </nav>

                                    <div className="action-bar">
                                        <a href="mailto:support@ncikenya.go.ke"><i className="fal fa-envelope"></i>support@ncikenya.go.ke</a>
                                        <a href="tel:+254795012568"><i className="fal fa-phone"></i>(+254) (0) 795 012 568</a>
                                        <a href="https://portal.ncikenya.go.ke" target="_blank" rel="noopener noreferrer" className="d-btn theme-btn">Visit NCI-K Portal</a>
                                    </div>
                                </div>
                            </div>
                            <div className="overlay"></div>
                        </div>
                    </div>
                </div>
            </>}
                {subMenu ? <ContentMenu menu={subMenu} pageTitle={pageTitle} eventTime={eventTime} setSelectedTab={setSelectedTab} hideMainMenu={hideMainMenu}/> : null}
            </header>
        </Fragment>
    );
}

export default Menu;
