import React, {Fragment, useState, useEffect, useCallback} from 'react';
import Header from "../layout/header";
import {Tab} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import News from "./tenders";
import Events from "./procurement";

const Procurement = (props) => {

    const { hash } = useLocation();

    const pageTitle ="Procurement";
    const menu = [
        // {title: "Registration", key:"procurement-registration", component: ()=><Events/>},
        {title: "Tenders", key:"tenders", component: ()=><News/>},
    ];

    const [selectedTab, setSelectedTab] = useState();

    useEffect(() => {
        setSelectedTab(hash.replace("#", "").trim().length>0?hash.replace("#", ""):menu[0].key)
    }, [hash])

    return (
        <Fragment>
            <Tab.Container id={pageTitle.toLowerCase().replaceAll(" ", "-") + "-tabs"} activeKey={selectedTab}>
                <Header active={"work-with-us"} subMenu={menu} pageTitle={pageTitle} setSelectedTab={setSelectedTab}/>
            <div className={"pt-10"}>
                <Tab.Content>
                    {menu.map(menuItem=>  <Tab.Pane key={menuItem.key} eventKey={menuItem.key}>
                        {menuItem.component()}
                    </Tab.Pane>)}
                </Tab.Content>
            </div>
            </Tab.Container>
        </Fragment>
    );
}

export default Procurement;
